import { Component, AbstractType, ElementRef, OnInit, OnDestroy, ViewEncapsulation  } from '@angular/core';

import { CRUD, Environment } from '../../config/environment';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';

import { TelemarketingService } from '../../services/telemarketing/telemarketing.service';
import { AplicantModel, DatosPersonalesModel, 
         DatosContactoModel, DatosLaboralesModel,
         DireccionModel, TelefonoModel, ObservacionesModel,
         EntregaDocumentacionModel, ObservacionesTackingModel, 
         ValidacionModel, AplicanteAdicionalModel, BusquedaFiltrosModel,
        } from 'src/app/models/aplicante.model';

import {  TiposDocumentosList, VinculosList,
          NacionalidadesList, EstadosCivilList, ProvinciasList,
          TiposViviendasList, EducacionNivleslList, ProfesionesList,
          TrabajosTipolList, ProductosList, CargoslList, GenerosList,
          AccionAplicanteList, MotivosAplicanteList, SourceCodesList} 
          from 'src/app/models/lists.model';

import * as _ from 'lodash';
import { ɵAnimationGroupPlayer } from '@angular/animations';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Config } from '../../config/config';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import * as moment from 'moment';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { utils } from 'protractor';
//import { identifierModuleUrl } from '@angular/compiler';
import { VendorFilterModel } from '../precheck/models/models';
import { Roles } from 'src/app/auth/models/roles.static';
import { BaseComponent } from '../shared/base.component';


@Component({
  selector: 'app-telemarketing',
  templateUrl: './telemarketing.component.html',
  styleUrls: ['./telemarketing.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class TelemarketingComponent extends BaseComponent implements OnInit {

  public title: string = "DETALLE DE APLICANTES";
  public tablereports = null;
  
  public es: any;
  public showResult : boolean;
  public showSearch: boolean;
  public showAplicanteTarjeta: boolean;
  public showAplicanteDetalles : boolean;
  public showAdicionalesEditar : boolean; 
  public cuilReadonly: boolean;

  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  public loading: boolean;
  public loadingMotivo: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public resultsData: Array<any> = new Array<any>();
  public aplicanteCurrent: AplicantModel = new AplicantModel;
  public aplicanteCurrentBk: AplicantModel = new AplicantModel;

  public adicionalCurrent: AplicanteAdicionalModel = new AplicanteAdicionalModel;
  public adicionalCurrentBk: AplicanteAdicionalModel = new AplicanteAdicionalModel;
 
  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public validationsAdicional: Array<any> = new Array<any>();
  public validationsAdicionalShow: boolean; 
  
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;

  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();
 
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public sourcecodesList: SourceCodesList = new SourceCodesList();
    
  public generosList: GenerosList = new GenerosList();
  public cargosList: CargoslList = new CargoslList();
  public productsApplicationSource: any = {data:[]};
  public vendorsList :Array<VendorFilterModel> = new Array<VendorFilterModel>(); 
  public vendorsListShow: boolean = true;
  public estadoValidacionMotivo = "";
  public estadosList: any = {data:[]};
  public validacionMotivoBk: string = "";  
  public rolConsulta : boolean=true;

  protected loadingListsBehavior: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadingLists: Array<any> = [
    {"load":"VENDORS", "finish": false},
    {"load":"ACCIONES", "finish": false},
    {"load":"ESTADOS", "finish": false},
    {"load":"PROFESIONES", "finish": false}    ,
    {"load":"PRODUCTOS", "finish": false},
    {"load":"SOURCECODES", "finish": false},

  ];


 constructor (private commonDataService: CommonDataService,
              private telemarketingService: TelemarketingService, 
              private sessionStorageService: SessionStorageService) {

                super()
                this.sessionStorageService.rolValidate("telemarketing");

                this.loadingListsBehavior = new BehaviorSubject<any>(null);
                this.loadingListsBehavior.subscribe(data  => { 
                
                    if(this.loadingLists.filter(l=> !l.finish).length == 0){
                      this.loading = false;
                    }
                 
                });  
                

  }

  ngOnInit() {   

    this.busquedafiltros.producto = "";
    this.busquedafiltros.estado = "";
    this.busquedafiltros.promotor = "";
    this.showSearch = true;      
    this.loading = true;

    this.setCalendarLocale();
    this.loadValidationAccion();
    this.loadVendors();
    this.loadProfesiones();
    this.loadProductos();
    this.loadSourcecodes();
    this.rolesAutorization();    
  }

  setLoadingListsFinish(loadname){

    this.loadingLists.forEach(i=>{
      if(i.load == loadname){
        i.finish = true;
        this.loadingListsBehavior.next(null);
      }

    })
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }

  




  loadReportResults(){

    if(Utils.dateIsValid(this.busquedafiltros.fechaDesde) &&
      Utils.dateIsValid(this.busquedafiltros.fechaHasta)){
      let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fechaDesde, this.busquedafiltros.fechaHasta, "days");

      if(cap_days < 0){
        this.alertMessage = new AlertModel("Fecha: Desde debe ser mayor que Hasta.",this.title,true);
        return;
      }  
    }
    
    this.loading = true;
    this.showAplicanteTarjeta = false;
    this.showAplicanteDetalles = false;

    this.loadReportRequest()
    return true;

  }

  loadReportRequest(){  

    let request = { 
      "matriz": this.sessionStorageService.getCurrentUser()?.matriz,
      "dni": Utils.isNullOrEmpty(this.busquedafiltros.dni) ? null : this.busquedafiltros.dni, 
      "fecha": Utils.isNullOrEmpty(this.busquedafiltros.fecha) ? null : this.busquedafiltros.fecha, 
      "nombreapellido": Utils.isNullOrEmpty(this.busquedafiltros.nombre) ? null : this.busquedafiltros.nombre, 
      "pcn": Utils.isNullOrEmpty(this.busquedafiltros.pcn) ? null : this.busquedafiltros.pcn, 
      "producto": Utils.isNullOrEmpty(this.busquedafiltros.producto) ? null : this.busquedafiltros.producto,
      "promotor": Utils.isNullOrEmpty(this.busquedafiltros.promotor) ? null : this.busquedafiltros.promotor,
      "fechaDesde": Utils.isNullOrEmpty(this.busquedafiltros.fechaDesde) ? null : this.busquedafiltros.fechaDesde, 
      "fechaHasta": Utils.isNullOrEmpty(this.busquedafiltros.fechaHasta) ? null : this.busquedafiltros.fechaHasta, 
    };
    

    this.telemarketingService.getApplicantsReportByArea(request).subscribe(
      data => {
        if (data['data']) {
          this.resultsData = data['data']; 

          this.resultsData.forEach(i=>{
            i.producto = this.productosList.data.find(p=>p.key == (i.producto??""))?.value ?? "";
          });

          if(!Utils.isNullOrEmpty(this.busquedafiltros.estado)){
            this.resultsData = this.resultsData.filter(f=>f.estado.toUpperCase() == this.busquedafiltros.estado.toUpperCase());
          }

          this.setupTable();
          

        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;
  }

  showAplicante(pcn:string){
    this.loading = true;

      let request = {
        "pcn" : pcn
      };

      this.telemarketingService.getApplicantDataByArea(request).subscribe(
        data => {

          if (data && data['code'] == "100"){
            this.alertMessage = new AlertModel(data['message'] , this.title, true);
            this.loading = false;
            return;
          }
       
          if (data && data.data && data['code'] == "0") {

            if(data.data.length == 0){
              this.loading = false;
              this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, "ERROR");   
              return;
            }

            if(!Utils.isNullOrEmpty(data.data[0].datos_personales.fecha_nacimiento)){

              if(!Utils.dateIsValid(data.data[0].datos_personales.fecha_nacimiento)){
               
                if(Utils.dateIsValid(data.data[0].datos_personales.fecha_nacimiento.split(" ")[0])){
                  data.data[0].datos_personales.fecha_nacimiento = data.data[0].datos_personales.fecha_nacimiento.split(" ")[0];
                }else
                  data.data[0].datos_personales.fecha_nacimiento = "";
                
              }
            }

            this.aplicanteCurrent = Object.assign(this.aplicanteCurrent, data.data[0]);
            if( this.aplicanteCurrent.observaciones_tracking.length ){
              this.aplicanteCurrent.observaciones_tracking.sort(function(a, b) {
                var textA = a.fecha;
                var textB = b.fecha;
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
              });
            }
            this.aplicanteCurrent.observaciones.observacion = "";

            let accion = this.accionAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.accion);
            
            this.estadoValidacionMotivo = this.aplicanteCurrent.validacion.motivo;
            if(accion){
              this.aplicanteAccionChange(this.aplicanteCurrent.validacion.accion);
            } else{
              this.aplicanteCurrent.validacion.accion = "";
              this.aplicanteCurrent.validacion.motivo = "";
            }

            this.aplicanteCurrent.adicionales = this.aplicanteCurrent.adicionales.filter(a=>{

              if(!Utils.dateIsValid(a.fecha_nacimiento)){
                a.fecha_nacimiento = null;
              }

              return !Utils.isNullOrEmpty(a.numero_documento);
            }); 
            
            this.aplicanteCurrent.detalles.cantidad_suples = this.aplicanteCurrent.adicionales.length.toString();
            this.aplicanteCurrent.datos_personales.nombre_completo = this.aplicanteCurrent.datos_personales.nombre_completo?.trim();
            this.aplicanteCurrent.datos_personales.nombre_primero = this.aplicanteCurrent.datos_personales.nombre_primero?.trim();
            this.aplicanteCurrent.datos_personales.nombre_segundo = this.aplicanteCurrent.datos_personales.nombre_segundo?.trim();
            this.aplicanteCurrent.datos_personales.apellido_primero = this.aplicanteCurrent.datos_personales.apellido_primero?.trim();
            this.aplicanteCurrent.datos_personales.cantRellamados = this.aplicanteCurrent.datos_personales.cantRellamados;
            if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.estado_civil))
               this.aplicanteCurrent.datos_personales.estado_civil = "NA";

            if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.tipo_documento))
               this.aplicanteCurrent.datos_personales.tipo_documento = "DNI";


            this.cuilReadonly = !Utils.isNullOrEmpty(this.aplicanteCurrent.datos_laborales.cuil);
            this.aplicanteCurrentBk = JSON.parse(JSON.stringify(this.aplicanteCurrent));
            this.refactorCp();  
            this.validationsShow = false;
            this.showAplicanteDetalles = false;
            this.showAplicanteTarjeta = true;
            this.showResult = false
            this.showSearch = false;
            this.loading = false;
            this.setupTootip();
            this.loadProductsApplicationSource();
            window.scroll(0, 0);

            this.direccionBinding()
            this.commonDataService.pingApplicantHoldTimerStart(pcn);

          }
          else {
            console.error(data);
            this.loading = false;
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
          }
        },
        error => {
          console.error(error);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      );      
      

    return true;
  
  }

  saveAplicanteTarjeta(){
    
    if(!this.rolConsulta){
      this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
      return;
    } 

    if(!this.validationsForm()){
      this.validationsShow = true;
      this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
      return;
    } 

    let estados = [
        "CONTACTADO",
        "A ESPERA DE DOCUMENTACION",
        "FALTA DOC",
        "FALTA DNI",
        "FALTA INGRESOS"
      ];

      
    if(this.aplicanteCurrent.validacion.accion.toUpperCase() == "VENTA" &&
       estados.includes(this.aplicanteCurrent.validacion.motivo.toUpperCase())) {



      this.showAplicanteDetalles = true;
      this.showAplicanteTarjeta = false;
      this.validationsShow = false;
      this.setupTootip();

      window.scroll(0, 0);

    }else{

      

      this.aplicanteCurrent.usuario = this.sessionStorageService.getCurrentUser().username; 
      this.validacionMotivoBk  =  this.aplicanteCurrent.validacion.motivo; 

      this.saveAplicante();
     
    }


  }

  confirmSave(){

    if(!this.validationsForm()){
      this.validationsShow = true;
      this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
      return;
    }
    
    this.alertConfirmEvent = this.onConfirmEvent;
    this.alertMessage = new AlertModel("Confirma que desea registrar la información?",this.title,true, "CONFIRM");	
  }


  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveAplicanteComplete();
    }

    return true;
  }

  saveAplicanteComplete() {
    this.validationsShow = false;

    this.validacionMotivoBk  =  this.aplicanteCurrent.validacion.motivo;  
    this.aplicanteCurrent.validacion.motivo = "A ESPERA DE DOCUMENTACION";  

    this.saveAplicante();
    
    return true;
  }

  public saveAplicante() {    
    
    this.loading = true;

    this.aplicanteCurrent.usuario = this.sessionStorageService.getCurrentUser().username; 
    this.aplicanteCurrent.promotor = this.sessionStorageService.getCurrentUser()?.promotor;


    this.telemarketingService.saveApplicantDataByArea(this.aplicanteCurrent).subscribe(
      data => {
        if (data != null && data['code'] == "0") {
          
          this.loading = false;
          window.scroll(0, 0);
          this.showAplicanteTarjeta = false;
          this.showAplicanteDetalles = false;
          this.validationsShow = false;
          this.showResult = true;
          this.showSearch = true;
          this.refactorCp();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadReportResults();
        }
        else {
          this.aplicanteCurrent.validacion.motivo  = this.validacionMotivoBk;
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
      },  
      error => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();

        this.aplicanteCurrent.validacion.motivo  = this.validacionMotivoBk;
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
      }
    ); 
    
    return true;
}

  messageTrackingShow(){
      this.messagetrackingshow = true;
  }

  
  adicionalesList(){

    let adicionales = this.aplicanteCurrent.adicionales.filter((a)=>{

      return a.crud != CRUD.DELETE;
    });

    return adicionales;
  }
  

  adicionalesEditar(dni:any) {

    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{
      return a.numero_documento == dni;
    });

    let adicionalBk = this.aplicanteCurrentBk.adicionales.find((a)=>{
      return a.numero_documento == dni;
    });

    this.adicionalCurrent = Object.assign({}, adicional);
    this.adicionalCurrentBk = adicional;
    this.adicionalCurrent.crud = adicionalBk ? CRUD.UPDATE : CRUD.CREATE;

    this.showAdicionalesEditar = true;
    return true;
  }

  adicionalesNuevo() {
      let adicionales = this.aplicanteCurrent.adicionales.filter(a=>a.crud != CRUD.DELETE);
      if(adicionales.length >= 5){
        this.alertMessage = new AlertModel("Solo se permiten hasta 5 adicionales.", this.title,true, ALERT_TYPE.INFO);	
        return false;
      }

      this.adicionalCurrent  = new  AplicanteAdicionalModel();
      this.adicionalCurrentBk  = null;
      this.adicionalCurrent.crud = CRUD.CREATE;
      this.showAdicionalesEditar = true;

      return true;
 }

  adicionalesCancelar(){

    this.showAdicionalesEditar = false;
    this.adicionalCurrent = null;
    this.validationsAdicionalShow = false;
    this.validationsAdicional = [];
    return true;
  }

  adicionalesGuardar(){

    if(!this.validationsAdcionalForm()){
      this.validationsAdicionalShow = true;
      return false;
    }
    console.log(this.adicionalCurrent)
    this.adicionalCurrent.numero_documento = this.adicionalCurrent.numero_documento.replace(/[^0-9]+/,"");
    
    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{
      return a.numero_documento == this.adicionalCurrent.numero_documento &&
             a.crud != CRUD.DELETE;
    });
    
    let dniChangeCurrent = adicional && !this.adicionalCurrentBk ? adicional.numero_documento == this.adicionalCurrent.numero_documento: false;
    let dniChangeOriginal = adicional && this.adicionalCurrentBk ? this.adicionalCurrentBk.numero_documento != this.adicionalCurrent.numero_documento: false;
    
    if(dniChangeCurrent  || dniChangeOriginal){
      this.alertMessage = new AlertModel("Ya existe un adicional con el mismo número documento.", this.title,true, ALERT_TYPE.INFO);	
      return false;      
    }

    
    if(adicional || this.adicionalCurrentBk) {
        let index = this.aplicanteCurrent.adicionales.indexOf(adicional ?? this.adicionalCurrentBk);

        this.aplicanteCurrent.adicionales[index] = Object.assign({},  this.adicionalCurrent );
        this.aplicanteCurrent.adicionales[index].usuario = this.sessionStorageService.getCurrentUser().username;
        
    }else{
      this.adicionalCurrent.usuario = this.sessionStorageService.getCurrentUser().username;
      this.aplicanteCurrent.adicionales.push(this.adicionalCurrent);
      
    }

    this.adicionalCurrentBk = null;
    this.showAdicionalesEditar = false;
    return true;

  }

  adicionalesBorrarConfirm(confirm:any){

    if(confirm.confirm){
      let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

        return a.numero_documento == confirm.data;
      });
  
     let index =  this.aplicanteCurrent.adicionales.indexOf(adicional);

     this.aplicanteCurrent.adicionales[index].crud = CRUD.DELETE;;
    }
  }

  
  adicionalesBorrar(dni:any){

    this.alertConfirmEvent = this.adicionalesBorrarConfirm;
    this.alertMessage = new AlertModel("Esta acción eliminará el siguiente registro: " + dni + "\nEsta seguro de continuar?",this.title,true, "CONFIRM", dni);	
        
  }

  loadValidationAccion(){

    if(Utils.storageLoad("acciones-tlmk")){
      this.accionAplicanteList.data = Utils.storageLoad("acciones-tlmk");      
      this.estadosFilterLoad();
      this.setLoadingListsFinish("ACCIONES");
      return;
    }

    this.telemarketingService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
          Utils.storageSave("acciones-tlmk",  this.accionAplicanteList.data);          
        }
        else
          console.error(data);        

        this.estadosFilterLoad();
        this.setLoadingListsFinish("ACCIONES");
      },
      error => {
        this.setLoadingListsFinish("ACCIONES");
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;

  }

  aplicanteAccionChange(item){
    if(item != "VENTA")
       this.validationsShow = false;

    this.loadingMotivo = true;
    let request = {"parent" : item}

    this.telemarketingService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];

          let motivo = this.motivosAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.motivo);
          if(motivo == null){
            this.aplicanteCurrent.validacion.motivo = "";
          }
        
          this.motivosAplicanteList.data.sort((a, b)=> a.key.localeCompare(b.key));  
          
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }

        this.loadingMotivo = false;
      },
      error => {
        this.loadingMotivo = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
    
  }

  public loadProductsApplicationSource(){

    let request = {
      "promotor" : this.aplicanteCurrent.detalles.promotor_original
    }

    this.telemarketingService.getProductsApplicationSource(request).subscribe(
      data => {
        this.productsApplicationSource.data = data["data"]
        
        if(this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent.solicitud_tarjeta.application_source_desc) == null)
          this.aplicanteCurrent.solicitud_tarjeta.application_source_desc = "";
          this.disableInputRolconsulta()

        },
      error => console.error(error));   

  }

  public disableInputRolconsulta(){

  if (!this.rolConsulta){
    const inputs  = document.getElementsByClassName('form-control');
    const guardarBtn  = document.getElementsByClassName('rolConsultaButton');
    for(let i=0;i<inputs.length;i++){
        inputs[i].setAttribute("disabled","true")
    }
    for(let i=0;i<guardarBtn.length;i++){
      guardarBtn[i].setAttribute("disabled","true")
  }
    
  }
}

public rolesAutorization(){
  let rolesSession = this.sessionStorageService.getCurrentUser().roles
    console.log(rolesSession)
  if (rolesSession.includes("AMX_CONSULTA")){
    this.rolConsulta=false
  }
}

  public applicationSourceChange(productoId){

    this.aplicanteCurrent.detalles.producto = productoId;

    let promotor_original = this.aplicanteCurrent.detalles.promotor_original;
    let source = this.sourcecodesList.data.find(s=>s.prod ==  productoId && s.promotor == promotor_original);

    if(source){   
      
      this.aplicanteCurrent.solicitud_tarjeta.application_source = source.source_code_completo;
      this.aplicanteCurrent.solicitud_tarjeta.application_source_desc = source.prod;
    }

  }

  backToResults(){

    if(this.showAplicanteDetalles){

      this.showAplicanteDetalles = false;
      this.showAplicanteTarjeta = true;
      return;     
    }


    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.solicitud_tarjeta.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
    }

    this.showAplicanteDetalles = false;
    this.showAplicanteTarjeta = false;
    this.validationsShow = false;
    this.showResult = true;
    this.showSearch = true;
    this.loading = false;

    return true;
  }


  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

    return true;
  }
  
  setCalendarLocale(){
    this.es = Environment.CalendarLanguageSetting;
  }


  validationsForm(){
    this.validations = new Array<any>();

    if(this.accionAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.accion) == null){
      this.aplicanteCurrent.validacion.accion = "";
      this.validations.push({"validacion.accion" : "este campos es requerido."})
    }  


    if(this.motivosAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.motivo) == null){
      this.aplicanteCurrent.validacion.motivo = "";
      this.validations.push({"validacion.motivo" : "este campos es requerido."})
    } 

    if(this.aplicanteCurrent.validacion.accion.toUpperCase() != "VENTA" )
       return this.validations.length == 0;
    
    if(this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent.solicitud_tarjeta.application_source_desc) == null){
        this.validations.push({"solicitud_tarjeta.application_source_desc" : "este campos es requerido."})
      } 

    if(this.tiposDocumentosList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.tipo_documento) == null){
      this.aplicanteCurrent.datos_personales.tipo_documento = "";
      this.validations.push({"datos_personales.tipo_documento" : "este campos es requerido."})
    } 

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.numero_documento))
       this.validations.push({"datos_personales.numero_documento" : "este campos es requerido."})
       
    if(this.generosList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.genero) == null){
      this.aplicanteCurrent.datos_personales.genero = "";
      this.validations.push({"datos_personales.genero" : "este campos es requerido."})
    } 

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.nombre_primero)){
       this.validations.push({"datos_personales.nombre_primero" : "este campos es requerido."});
	}

 
	if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.apellido_primero)){
       this.validations.push({"datos_personales.apellido_primero" : "este campos es requerido."});
	}	   

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.nombre_completo)){
       this.validations.push({"datos_personales.nombre_completo" : "este campos es requerido."});
	}

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.fecha_nacimiento)){
       this.validations.push({"datos_personales.fecha_nacimiento" : "este campos es requerido."});
	}

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.fecha_nacimiento)){

      let b = moment(this.aplicanteCurrent.datos_personales.fecha_nacimiento, "DD/MM/YYYY");

      if(moment().diff(b, 'years') < 18)       
        this.validations.push({"datos_personales.fecha_nacimiento" : "no se permite menor a 18 años"})
    }      
    
    if(this.nacionalidadesList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.nacionalidad) == null){
      this.aplicanteCurrent.datos_personales.nacionalidad = "";
      this.validations.push({"datos_personales.nacionalidad" : "este campos es requerido."})
    }

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.celular))
        this.validations.push({"datos_contacto.celular" : "este campos es requerido."});

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal))
        this.validations.push({"datos_contacto.email_personal" : "este campos es requerido."});
    

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
      let rxEmail = new RegExp(Environment.RegExEmail);
      if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
        this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
      }
    }
    



    if(this.showAplicanteDetalles){

     

        if(this.provinciasList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.provincia) == null){
          this.aplicanteCurrent.datos_personales.provincia = "";
          this.validations.push({"datos_personales.provincia" : "este campos es requerido."});
        }     
        
        if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.localidad))
          this.validations.push({"datos_personales.localidad" : "este campos es requerido."});
        
        if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.codigo_postal))
          this.validations.push({"datos_personales.codigo_postal" : "este campos es requerido."});
        
        if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.calle))
          this.validations.push({"datos_personales.calle" : "este campos es requerido."});

        if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.calle_nro))
           this.validations.push({"datos_personales.calle_nro" : "este campos es requerido."});     
        

        if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.celular))
           this.validations.push({"datos_contacto.celular" : "este campos es requerido."});
    
        if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal))
           this.validations.push({"datos_contacto.email_personal" : "este campos es requerido."});
        
        if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
          let rxEmail = new RegExp(Environment.RegExEmail);
          if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
            this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
          }
        }

        if(this.educacionNivleslList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.educacion) == null){
          this.aplicanteCurrent.datos_personales.educacion = "";
          this.validations.push({"datos_personales.educacion" : "este campos es requerido."})
        }  


        if(this.tiposViviendasList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.vivienda) == null){
          this.aplicanteCurrent.datos_personales.vivienda = "";
          this.validations.push({"datos_personales.vivienda" : "este campos es requerido."})
        }  
          
        if(this.nacionalidadesList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.nacionalidad) == null){
          this.aplicanteCurrent.datos_personales.nacionalidad = "";
          this.validations.push({"datos_personales.nacionalidad" : "este campos es requerido."})
        }          
          
        if(this.profesionesList.data.find(v=>v.key==this.aplicanteCurrent.datos_laborales.profesion) == null){
          this.aplicanteCurrent.datos_laborales.profesion = "";
          this.validations.push({"datos_laborales.profesion" : "este campos es requerido."})
        }  



        if(this.trabajosTipolList.data.find(v=>v.key==this.aplicanteCurrent.datos_laborales.tipo_trabajador) == null){
          this.aplicanteCurrent.datos_laborales.tipo_trabajador = "";
          this.validations.push({"datos_laborales.tipo_trabajador" : "este campos es requerido."})
        }  

        if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_laborales.antiguedad))
          this.validations.push({"datos_laborales.antiguedad" : "este campos es requerido."});

      
    }
     

    return this.validations.length == 0;
  }


  validationsMessage(name):string{
    if(!this.validationsShow){
      return null;
    }

    this.validationsForm();

    let validation = this.validations.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  validationsAdcionalForm(){
    this.validationsAdicional = new Array<any>();


    if(this.tiposDocumentosList.data.find(v=>v.key==this.adicionalCurrent.tipo_documento) == null){
      this.adicionalCurrent.tipo_documento = "";
      this.validationsAdicional.push({"adicionalCurrent.tipo_documento" : "este campos es requerido."})
    }  

    if(Utils.isNullOrEmpty(this.adicionalCurrent.numero_documento))
             this.validationsAdicional.push({"adicionalCurrent.numero_documento" : "este campos es requerido."});

    
    if(Utils.isNullOrEmpty(this.adicionalCurrent.fecha_nacimiento))
        this.validationsAdicional.push({"adicionalCurrent.fecha_nacimiento" : "este campos es requerido."});


    if(!Utils.isNullOrEmpty(this.adicionalCurrent.fecha_nacimiento)){

      let b = moment(this.adicionalCurrent.fecha_nacimiento, "DD/MM/YYYY");

      if(moment().diff(b, 'years') < 14)       
        this.validationsAdicional.push({"adicionalCurrent.fecha_nacimiento" : "no se permite menor a 14 años"})
    }         
    
    if(Utils.isNullOrEmpty(this.adicionalCurrent.nombre_completo))
        this.validationsAdicional.push({"adicionalCurrent.nombre_completo" : "este campos es requerido."});


    if(Utils.isNullOrEmpty(this.adicionalCurrent.nombre_primero))
        this.validationsAdicional.push({"adicionalCurrent.nombre_primero" : "este campos es requerido."});


    if(Utils.isNullOrEmpty(this.adicionalCurrent.apellido_primero))
        this.validationsAdicional.push({"adicionalCurrent.apellido_primero" : "este campos es requerido."});


    if(this.vinculosList.data.find(v=>v.key==this.adicionalCurrent.vinculo) == null){
      this.adicionalCurrent.vinculo = "";
      this.validationsAdicional.push({"adicionalCurrent.vinculo" : "este campos es requerido."})
    }  


    if(this.generosList.data.find(v=>v.key==this.adicionalCurrent.genero) == null){
      this.adicionalCurrent.genero = "";
      this.validationsAdicional.push({"adicionalCurrent.genero" : "este campos es requerido."})
    }      
    
    

    return this.validationsAdicional.length == 0;
  }

  estadosFilterLoad(){

    this.commonDataService.getValidationAccionAll().subscribe(
      data => {
        if(data) {
          let estados = data as Array<any>;
            
          estados = estados.filter(e=>{ 

            return  e.area == "TELEMARKETING" 
                || (e.area == "PRECHECK" && e.subestado.includes("CON NSE"))
                || (e.area == "RECOVERY" && e.subestado.includes("DOWNGRADE"))
                || (e.area == "NEWACCOUNT" && e.subestado.includes("DOC ILEGIBLE")) 
                || (e.area == "NEWACCOUNT" && e.subestado.includes("DOC FALTANTE")) ;
        
          })
          .concat([ {estado: "A TRABAJAR", subestado:"A TRABAJAR", area: "TELEMARKETING"}])
          .map(m => { 
            return {"key":m.subestado, "value":m.subestado, "parent":m.estado};
          }).reduce((acc,e)=>{ 
            if(!acc.some(i=>i.value == e.value))
               acc.push(e);
            
            return acc;
          },[]);

          estados.sort((a, b)=> a.key?.localeCompare(b.key));  
         
          this.estadosList.data = estados;
   
        }

        this.setLoadingListsFinish("ESTADOS");

      },
      error =>{
          this.setLoadingListsFinish("ESTADOS");
      }
    )

  }


  public validationsAdcionalMessage(name):string{
    if(!this.validationsAdicionalShow){
      return null;
    }
      
    this.validationsAdcionalForm();

    let validation = this.validationsAdicional.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  listAplicanteDireccinesTelefonos(origen:string){

    let data = Array<any>();
    let direciones = this.aplicanteCurrent.direcciones.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let telefonos = this.aplicanteCurrent.telefonos.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let length = (direciones.length >= telefonos.length) ? direciones.length : telefonos.length;

    for(let i = 0; i < length; i++){
      data.push({
        "direccion" : direciones[i] != null ? direciones[i].direcciones : "",
        "telefono" : telefonos[i] != null ? telefonos[i].telefono : "",
      });   
    }
   
    return data;
  }
  
  loadVendors(){

    if(Utils.storageLoad("vendors-tlmk")){
      this.vendorsList = Utils.storageLoad("vendors-tlmk");
      this.setLoadingListsFinish("VENDORS");
      return;
    }

    this.telemarketingService.getVendorsList().subscribe(
      data => {
        if (data['data']) {
          
          this.vendorsList = Object.assign(this.vendorsList, data['data']);
    
          this.vendorsList.sort((a, b)=> a.name.localeCompare(b.name));  

          let user = this.sessionStorageService.getCurrentUser();
          
          if(!Utils.isNullOrEmpty(user.promotor)){            
            

            if(this.vendorsList.length == 1){
              this.vendorsListShow = false;
              this.busquedafiltros.promotor = user.promotor;
            } else{
              this.vendorsListShow = true;
              this.busquedafiltros.promotor = "";
            }
            
          } else {         
            this.vendorsListShow = true;
          }

          Utils.storageSave("vendors-tlmk",  this.vendorsList);  

        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("VENDORS")
      },
      error => {
        this.setLoadingListsFinish("VENDORS")
        console.error(error);
      }
    );

    
    

  }

  loadProfesiones(){

    this.commonDataService.getProfesiones().subscribe(
      data => {
        if (data['data']){
          this.profesionesList.data = data['data'];
          this.profesionesList.data.sort((a, b)=> a.value?.localeCompare(b.value));      
        }        
        else {
          console.error(data);  
        }
          
        this.setLoadingListsFinish("PROFESIONES")
        
      },
      error => { 
        console.error(error); 
        this.setLoadingListsFinish("PROFESIONES");
      }
    );

    return true;

  }

  loadProductos(){

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("PRODUCTOS");
      },
      error => {
        this.setLoadingListsFinish("PRODUCTOS");
      }
    );

    
    

  }

  loadSourcecodes(){

    this.commonDataService.getSourcecodes().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.sourcecodesList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("SOURCECODES");
      },
      error => {
        this.setLoadingListsFinish("SOURCECODES");
      }
    );

    
    
    

  }

  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.resultsData.forEach(item => {      
      reportDataExport.push({
        "NOMBRE Y APELLIDO":item.nombreapellido,
        "CUIL":item.cuit,
        "DNI":item.dni,
        "PCN":item.pcn,
        "FECHA":item.fecha,
        "ULTIMA MODIF":item.fecha_modificacion,
        "PRODUCTO":item.producto,
        "ESTADO":item.estado,
              
      });
    }); 
    
    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);
  }  



  disabledTipoDoc():boolean{
    if(this.tiposDocumentosList.data.find(v=>v.key==this.aplicanteCurrent.datos_personales.tipo_documento) == null){
      this.aplicanteCurrent.datos_personales.tipo_documento = "";
      return false;
    } 

    return true;

  }

 
  direccionBinding(){    
  
    let direccion = (this.aplicanteCurrent.datos_personales.calle || "") + ' ' + 
        (this.aplicanteCurrent.datos_personales.calle_nro || "") + ' ' + 
        (this.aplicanteCurrent.datos_personales.calle_piso || "") + ' ' + 
        (this.aplicanteCurrent.datos_personales.calle_dpto || "");    

    this.aplicanteCurrent.datos_personales.direccion = direccion;
  }

  public  setupTootip(){
    $(function () {
        (<any>$('[data-toggle="tooltip"]')).tooltip()
    })
  
    return true;
  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.aplicanteCurrent.observaciones.observacion=this.sanitizedText(this.aplicanteCurrent.observaciones.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorCp(){ 
    this.aplicanteCurrent.datos_personales.codigo_postal=this.sanitizedTextCp(this.aplicanteCurrent.datos_personales.codigo_postal); 
     }
 sanitizedTextCp(text:string):string{
   return text.replace(/[^0-9]/g,'')
 }

 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 }

 public onKey(e){
  if(e.code == "Enter"){
    this.loadReportResults();
  }
}
 
onCont(operador){

  if(operador == "add"){

    this.aplicanteCurrent.datos_personales.cantRellamados = this.aplicanteCurrent.datos_personales.cantRellamados + 1;
  } else {
    if(this.aplicanteCurrent.datos_personales.cantRellamados > 0){
      this.aplicanteCurrent.datos_personales.cantRellamados = this.aplicanteCurrent.datos_personales.cantRellamados - 1;
    }

  }
  return this.aplicanteCurrent.datos_personales.cantRellamados;
}
}
