import { AbstractType, Component, ElementRef, OnInit} from '@angular/core';

import { LoadingComponent } from '../../component/shared/loading/loading.component';
import * as _ from 'lodash';
import { observeOn } from 'rxjs/operators';
import { Environment } from 'src/app/config/environment';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { Utils } from 'src/app/commons/utils';
import { User } from 'src/app/auth/models/user.model';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { ProductosList } from 'src/app/models/lists.model';
import { BaseComponent } from '../../component/shared/base.component';
import { BusquedaFiltrosModel , Vendor } from './models/models';
import { InternalSuplesRejectedService } from 'src/app/services_internal/internalSuplesRejected/internalSuplesRejected.service';

@Component({
  selector: 'app-internalDelivery',
  templateUrl: './internalSuplesRejected.component.html',
  styleUrls: ['./internalSuplesRejected.component.css']
})

export class InternalSuplesRejectedComponent extends BaseComponent  implements OnInit {

   
  public title = "Reporte de Suples Denegados - Customer";
  public es: any;
  public showResult : boolean;
  public activo: boolean = false;
  public validado: boolean = true;
  public denegado: boolean = true;
  public loading: boolean;
  public precheckDataList = []; 
  public productosList :ProductosList = new ProductosList();
  public tablereports = null;
  public alertMessage: AlertModel = new AlertModel;
  public setDateFormat: Function = Utils.setDateFormat;
  public showAdicionalesEditar : boolean;
  public resultsData:any = [];
  public promotoresList: Vendor[] = new Array<Vendor>();
  public vendorRoles = [ Roles.AMX_ADMIN, Roles.AMX_MANAG];

  public canalesList: Array<any> = [
    { id: 'Eapply', name: 'EAPPLY' },
    { id: 'Telemarketing', name: 'TELEMARKETING' },
    { id: 'Cen', name: 'CEN' },
  ];

  public ventaList: Array<any> = [
    { id: 'RCO', name: 'REVERSE COMPANION' },
    { id: 'CON', name: 'CONVERSION (UPGRADE)' },
    { id: 'SUP', name: 'VENTA ISOLADA' },
  ];

  public estadosList: Array<any> = [
    { id:'RECHAZADO' ,name: 'RECHAZADO' },
    { id:'TITULAR DENEGADO' ,name: 'TITULAR DENEGADO' },
    { id:'APROBADO' ,name: 'DENEGADO' },
  ];


  public vendorsListShow: boolean = true;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;  

  constructor(private suplesRejectedService: InternalSuplesRejectedService,
              private commonDataService: CommonDataService,
              private sessionStorageService: SessionStorageService,
              private excelExportService: ExcelExportService) { 
                super();
                this.sessionStorageService.rolValidate("reportSupleDenied");
              }

  ngOnInit() {
    this.showResult=false;
    this.setCalendarLocale(); 
    this.loadProductos();
    this.loadPromotores();
    
    this.busquedafiltros.producto = "";
    this.busquedafiltros.promotor = "";
    this.busquedafiltros.canalArchivo = "";
    this.busquedafiltros.estado = "";
    this.busquedafiltros.tipoVenta = "";

    let user = this.sessionStorageService.getCurrentUser();

  }
  setCalendarLocale(){ 
    this.es = Environment.CalendarLanguageSetting
  }

  loadReport(){
    
    if(!Utils.dateIsValid(this.busquedafiltros.fechaCapturaInicio)) {   
      this.alertMessage = new AlertModel("Fecha de Captura Inicio es requerida.", this.title, true, ALERT_TYPE.INFO);
      return;
    }

    if(!Utils.dateIsValid(this.busquedafiltros.fechaCapturaFin)) {     
      this.alertMessage = new AlertModel("Fecha de Captura final es requerida.", this.title, true, ALERT_TYPE.INFO);
      return;
    }

    if(Utils.dateIsValid(this.busquedafiltros.fechaCapturaInicio) &&
    Utils.dateIsValid(this.busquedafiltros.fechaCapturaFin)){
     let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fechaCapturaInicio, this.busquedafiltros.fechaCapturaFin, "days");

     if(cap_days < 0){
       this.alertMessage = new AlertModel("Fecha de Captura fin: Desde debe ser mayor que fecha de captura inicio.",this.title,true);
       return;
     }  

 }

    this.loading = true;
    this.loadReportRequest();
  }

  loadReportRequest(){   
    this.showResult = false;
    let request = { 
       
      "fechaCapturaInicio": Utils.parseEmptyNull(this.busquedafiltros.fechaCapturaInicio),
      "fechaCapturaFin": Utils.parseEmptyNull(this.busquedafiltros.fechaCapturaFin),
      "producto": Utils.isNullOrEmpty(this.busquedafiltros.producto) ? null : this.busquedafiltros.producto,
      "canalArchivo": Utils.isNullOrEmpty(this.busquedafiltros.canalArchivo) ? null : this.busquedafiltros.canalArchivo,
      "tipoVenta": Utils.isNullOrEmpty(this.busquedafiltros.tipoVenta) ? null : this.busquedafiltros.tipoVenta,
      "estado": Utils.isNullOrEmpty(this.busquedafiltros.estado) ?  null : this.busquedafiltros.estado,
      "promotor": Utils.isNullOrEmpty(this.busquedafiltros.promotor) ?  null : this.busquedafiltros.promotor,
      "activo": this.activo,
      "denegado": this.denegado,
      "validado": this.validado,
    };      

    this.suplesRejectedService.getIntPersonaSuplesRejected(request).subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          var data = response.body;
          this.resultsData = data['data'];

          this.resultsData.forEach(i=>{

            let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( i.producto ) );
            i.productoObj = prod;

            i.fechaCaptura = Utils.setDateNewFormat(i.fechaCaptura);
            i.fechaDenegado = Utils.setDateNewFormat(i.fechaDenegado);

            //(i.canal = "Telemarketing" ? "TLMK" : i.canal)
            i.canal = (i.canal === "Telemarketing") ? "TLMK" : i.canal;

            if(i.estado == "APROBADO"){
              i.estado = i.accion
              i.motivo = i.actualMotivo
            }
          })
          this.setupTable();
          

        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }

        this.loading = false;
      },
        err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        this.loading = false;
        throw err;
      }
    );
  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {

        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        //info: false,
        lengthChange: false,
        responsive: true,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          {
            targets: 13,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
          {
            targets: 14,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
        ],        
        language: Environment.DataTableLanguageSetting
        // language:{
        //   url:'//cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json',
        //   info: "Página _PAGE_ de _PAGES_"
        // }
       });
  
       $(".result-tlmk .row").css("overflow-x", "auto");

    });

  }

  loadPromotores(){
    this.suplesRejectedService.getAllPromotores().subscribe(
      response => {

        if( response.status == 200 && response.body != null){

          this.promotoresList = Object.assign([], response.body);

        }else if( response.status == 204 ){
          console.log( "no se encontraron promotores" );
        }else{
          console.log( "Error 1 obteniendo los promotores" );
        }

      }, err => {
        console.log( "Error 2 obteniendo los promotores" );
        this.loading = false;
        throw err;
      }
    );
  }

  loadProductos(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
      }
    );

  }

  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.resultsData.forEach(item => {   
      let objRow: any = new Object;
      objRow["PCN TITULAR"] = item.pcnTitular;
      objRow["CUIL TITULAR"] = item.cuilTit;
      objRow["4 DIGITOS"] = item.keyDigits;
      objRow["DNI SUPLE"] = item.dniSup;
      objRow.GENERO = item.genero;
      objRow["NOMBRE Y APELLIDO SUPLE"] = item.nombreCompletoSup;
      objRow["RELACION SUPLE"] = item.relacion;
      objRow["TIPO VENTA"] = item.tipoVenta;
      objRow.PROMOTOR = item.promotor;
      objRow.PRODUCTO = item.productoObj.data.prod+ " - " +item.productoObj.value;
      objRow.CANAL = item.canal;
      objRow.ESTADO = item.estado;
      objRow.MOTIVO = item.motivo;
      objRow["FECHA CAPTURA"] = item.fechaCaptura;
      objRow["FECHA DENEGADO"] = item.fechaDenegado;

      reportDataExport.push(objRow);
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  } 

  public rolConsulta:boolean=true;
  public rolesAutorization(){
    let rolesSession =this.sessionStorageService.getCurrentUser().roles

    if(rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  }
}
