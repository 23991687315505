import { AbstractType, Component, OnInit } from '@angular/core';

import { LoadingComponent } from '../../component/shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';

import { InternalDeliveryService } from '../../services_internal/internalDelivery/internalDelivery.service'
import { AplicantModel, AplicanteAdicionalModel, BusquedaFiltrosModel, CustomerModel, IntPersonaSuples, IntObservaciones 
        } from 'src/app/models/aplicante.model';

import {  TiposDocumentosList, VinculosList,
          NacionalidadesList, EstadosCivilList, ProvinciasList,
          TiposViviendasList, EducacionNivleslList, ProfesionesList,
          TrabajosTipolList, ProductosList, GenerosList, 
          AccionAplicanteList, MotivosAplicanteList, HorasList
        } 
          from 'src/app/models/lists.model';

import { listProvinceModel } from './models/model';
import { CommonDataService } from 'src/app/services/commons/commondata.service';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { CRUD, Environment } from 'src/app/config/environment';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Utils } from 'src/app/commons/utils';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../../component/shared/base.component';
import { data } from 'jquery';
import { Vendor } from '../../component/precheck/models/models';

@Component({
  selector: 'app-internalDelivery',
  templateUrl: './internalDelivery.component.html',
  styleUrls: ['./internalDelivery.component.css']
})

export class InternalDeliveryComponent extends BaseComponent  implements OnInit {

  
  public title: string = "DELIVERY DE PRODUCTOS";
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showAplicanteDetalles : boolean;
  public showAdicionalesEditar : boolean;
  public showSuples: boolean;
  public area: string = "DELIVERY";
  public ngSelectAccion: string = "";
  public ngSelect: string = "";
  public currentCustomerModel : CustomerModel = new CustomerModel();
  public listProvince: listProvinceModel = new listProvinceModel();
  public provinceData:any = [];
  
  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  public loading: boolean;
  public loadingMotivo: boolean; 
  public fechaPactacionDisabled: boolean = true;
  public fechaProxContactoDisabled: boolean = true;
  public setDateFormat: Function = Utils.setDateFormat;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public resultsData:any = [];
  public aplicanteCurrent: CustomerModel = new CustomerModel;
  public adicionalCurrent: AplicanteAdicionalModel = new AplicanteAdicionalModel;
  public adicionalesPerson: IntPersonaSuples = new IntPersonaSuples;
  public observacionesPerson: IntObservaciones = new IntObservaciones;

 
  dateinfo : Date;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;

  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();
  
  public generosList: GenerosList = new GenerosList();
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public horasList: HorasList = new HorasList();
  
  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public vendorsList: Array<any> = new Array<any>();
  public productsApplicationSource: any = {data:[]};
  public promotorUser: Vendor = new Vendor();
  public promotoresList: Vendor[] = new Array<Vendor>();
  public vendorsListShow: boolean = true;

  public rolConsulta : boolean=true;

  protected loadingListsBehavior: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadingLists: Array<any> = [
    {"load":"VENDORS", "finish": false},
    {"load":"ACCIONES", "finish": true},
    {"load":"PRODUCTOS", "finish": true}
  ];
    
 
  constructor(private commonDataService:CommonDataService,
              private deliveryInternalsService: InternalDeliveryService, 
              private sessionStorageService: SessionStorageService) { 
                super();
                this.sessionStorageService.rolValidate("internalDelivery");

                this.loadingListsBehavior.subscribe(data  => { 
                
                    if(this.loadingLists.filter(l=> !l.finish).length == 0){
                      this.loading = false;
                    }
                
                });  

  }

  ngOnInit() {

    this.loading = true;
    this.busquedafiltros.producto = "";
    this.busquedafiltros.promotor = "";
    
    this.setCalendarLocale();
    this.validationAccionLoad();
    this.loadVendors();
    this.loadProductos();  
    this.rolesAutorization();
    this.listProvinceLoad();
        
  }

  setLoadingListsFinish(loadname){

    this.loadingLists.forEach(i=>{
      if(i.load == loadname){
        i.finish = true;
        this.loadingListsBehavior.next(null);
      }

    })
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }

  loadReportResults(){

    this.loading = true;
    this.loadReportRequest();
  }

  loadReportRequest(){   
    this.showResult = false;
    let request = { 
       
      "matriz": this.sessionStorageService.getCurrentUser()?.matriz,
      "dni": Utils.parseEmptyNull(this.busquedafiltros.dni),
      "fecha": Utils.isNullOrEmpty(Utils.getTimesTamp(this.busquedafiltros.fecha)) ? null : Utils.getTimesTamp(this.busquedafiltros.fecha),
      "nombreapellido": Utils.parseEmptyNull(this.busquedafiltros.nombre),
      "cuil": Utils.parseEmptyNull(this.busquedafiltros.cuil),
      "pcn": Utils.parseEmptyNull(this.busquedafiltros.pcn),
      "producto": Utils.parseEmptyNull(this.busquedafiltros.producto),
      "refnaps": Utils.parseEmptyNull(this.busquedafiltros.refnaps),
      "aprobado_lanas": Utils.parseEmptyNull(this.busquedafiltros.aprobado_lanas) ,
      "promotor": Utils.parseEmptyNull(this.busquedafiltros.promotor),
      "area":  Utils.parseEmptyNull(this.area)
      
  };      

    this.deliveryInternalsService.getIntPersonaByArea(request).subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          this.resultsData = response.body;
          this.resultsData .forEach(a=>{
            if(Utils.isNullOrEmpty(a.aprobado_lanas)){
              a.aprobado_lanas = "-";
              a.estado_lanas = "-";
            }else{
              a.estado_lanas = "APROBADO";
            } 

            //a.fechaEntregado = "05/12/2022"
            
            // const suples = a.intPersonaSuples.filter(a => a.denegado === false)
            // a.sup = suples.length ? suples.length : "0";

            if( a.intPersonaSuples.length ){
              for( var j = 0; j < a.intPersonaSuples.length; j++ ){ 
                a.intPersonaSuples = a.intPersonaSuples.filter(function (sup) {
                  return sup.denegado !== true;
                })
              }
            }

            a.promotor = this.vendorsList.find(p=>p.id == (a.promotor??""))?.name ?? a.promotor;
            a.producto = this.productosList.data.find(p=>p.key == (a.producto??""))?.value ?? "";
            let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( a.informedProduct ) );

            if( a.tipoVenta == "SUP" && a.intSocioData != null && a.intSocioData.producto != null ){
              prod = this.productosList.data.find(p=> (p.key??"") == parseInt( a.intSocioData.producto ) );
            }

            a.productoObj = prod;
          });

          this.setupTable();

        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }

        this.loading = false;
      },
        err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        this.loading = false;
        throw err;
      }
    );
  }

  showAplicante(pcn:string){
      this.loading = true;
      let request = {
        "pcn": pcn
      };
  
      this.commonDataService.pingHoldInternal(request).subscribe(
        response => {
    
          if( response.status == 200 ){
  
            this.setDataToShow( pcn, response.body  );
  
          }else if( response.status == 226 && response.body != null){
  
            this.alertMessage = new AlertModel( "Este socio está siendo editado por "+response.body["message"],this.title, true,"WARNING");
            this.loading = false;
          }else{
  
            this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
            this.loading = false;
          }
          
        }, err => {
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
          this.loading = false;
          throw err;
        }
      
      );
 
  }

  setDataToShow(pcn:string, pers:any){
    this.ngSelectAccion = "";
    this.ngSelect = "";
      const aplicanteSelect = this.resultsData.find(item => item.pcn === pcn);

        if(aplicanteSelect){
            
            this.currentCustomerModel = aplicanteSelect
             this.aplicanteCurrent = Object.assign(this.aplicanteCurrent,  this.currentCustomerModel);
             this.currentCustomerModel.intObservaciones = pers.intObservaciones;
             this.currentCustomerModel.intPersonaSuples = pers.intPersonaSuples;
             ////

              if( this.currentCustomerModel.intPersonaSuples.length ){
        
                for( var j = 0; j < this.currentCustomerModel.intPersonaSuples.length; j++ ){
        
                  this.currentCustomerModel.intPersonaSuples = this.currentCustomerModel.intPersonaSuples.filter(function (sup) {
                    return sup.denegado !== true;
                  })
                }
              }
        
              ////
             if( this.currentCustomerModel.intObservaciones.length ){
               this.currentCustomerModel.intObservaciones.sort((a, b) => {
                  if (typeof a.id === 'number' && typeof b.id === 'number') {
                    return b.id - a.id;
                  }
                  return 0;
                });
             }
  
              if( !Utils.isNullOrEmpty( this.currentCustomerModel.intSocioData.province ) ){
                let nameProvince = this.provinceData.filter(data => data.codigo == this.currentCustomerModel.intSocioData.province);
                if( !Utils.isNullOrEmpty (nameProvince)){
                  this.currentCustomerModel.intSocioData.provinceName = nameProvince[0].descripcion
                }

              }
              
              let arrayTipoVentas = {"SUP":"Venta Isolada","RCO":"Reverse Companion","CON":"Conversion (Upgrade)","COM":"Companion"}
              this.currentCustomerModel.tipoVentaName = ( typeof arrayTipoVentas[ this.aplicanteCurrent.tipoVenta ] != "undefined" ) ? arrayTipoVentas[ this.aplicanteCurrent.tipoVenta ] : this.aplicanteCurrent.tipoVenta;
      
              if( this.currentCustomerModel.intPersonaSuples.length ){
                for( var i = 0; i < this.currentCustomerModel.intPersonaSuples.length; i++ ){
                  let obSuple = this.currentCustomerModel.intPersonaSuples[ i ];
                  obSuple.index = i+1;
                  let prodSuple = null;
                  if( Utils.isNullOrEmpty(obSuple.nombreCompleto) ){

                    if( Utils.isNullOrEmpty( obSuple.nombre2 ) ){
                      obSuple.nombreCompleto = obSuple.nombre1 + " " +obSuple.apellido;
                    }else{
                      obSuple.nombreCompleto = obSuple.nombre1 + " " + obSuple.nombre2 + " " +obSuple.apellido;
                    }
                    
                    obSuple.nombreCompleto = obSuple.nombreCompleto.toUpperCase();
                  }
                  if( obSuple.tipoDoc == "" || obSuple.tipoDoc == null ){
                    obSuple.tipoDoc = "DNI";
                  }

                  if( (this.currentCustomerModel.tipoVenta == "RCO" 
                  || this.currentCustomerModel.tipoVenta == "CON")
                  && this.currentCustomerModel.canalArchivo == "Telemarketing" 
                  && this.currentCustomerModel.productoObj.data.prod == "50"
                  && obSuple.plasticColorType != "00" ){
                    let prod = this.productosList.data.find(p=> (p.data.plasticColorType??"") == obSuple.plasticColorType );
                    obSuple.idProd = prod.key
      
                  }

                  if( !Utils.isNullOrEmpty( obSuple.idProd ) ){
                    prodSuple = this.productosList.data.filter(data => data.key == obSuple.idProd);
                    obSuple.productName = prodSuple[0].value;
                  }else{
                      obSuple.productName = this.currentCustomerModel.productoObj.value;
                    
                  }
                }
                this.showSuples = true;
                
              }else{
                this.showSuples = false;
              }
             this.currentCustomerModel.observacion = "";

             let accion = this.accionAplicanteList.data.find(a=>a.key == this.currentCustomerModel.accion);
             this.showAplicanteDetalles = true;
             if(accion){
               this.aplicanteAccionChange(this.currentCustomerModel.accion);
             } else{
               this.aplicanteCurrent.accion = "";
               this.aplicanteCurrent.motivo = "";
             }    


            this.showResult = false
            this.loading = false;    
            this.setupTootip();       
            window.scroll(0, 0);
            this.commonDataService.pingHoldInternalTimerStart(pcn);
          }else {
             this.loading = false; 
             this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,ALERT_TYPE.WARNING);
          }
  }

  confirmSave(){

    if(!this.rolConsulta){
      this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
      return;
    } 

    if( !Utils.isNullOrEmpty( this.ngSelectAccion ) && Utils.isNullOrEmpty( this.ngSelect ) ){
      this.alertMessage = new AlertModel("Seleccione un motivo",this.title, true, "WARNING");
      return;
    }

    this.alertConfirmEvent = this.onConfirmEvent;
    this.alertMessage = new AlertModel("Confirma que desea registrar la información?",this.title,true, "CONFIRM");	

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveAplicanteData();
    }

    return true;
  }

  saveAplicanteData(){

    let aplicante: any = new Object;
    aplicante = this.currentCustomerModel;
    aplicante.persona = this.sessionStorageService.getCurrentUser()?.username;    
    //aplicante.promotor = this.sessionStorageService.getCurrentUser()?.promotor;
    aplicante.observaciones = ( !Utils.isNullOrEmpty( this.currentCustomerModel.observacion ) ) ? this.currentCustomerModel.observacion : null;
    aplicante.accion = ( !Utils.isNullOrEmpty( this.ngSelectAccion ) ) ? this.ngSelectAccion : null;
    aplicante.motivo = this.ngSelect;
    aplicante.pcn = this.currentCustomerModel.pcn;

    if( !Utils.isNullOrEmpty( this.currentCustomerModel.fechaEntrega ) ){
      aplicante.fechaEntrega = Utils.getTimesTamp( this.currentCustomerModel.fechaEntrega );
    }else{
      aplicante.fechaEntrega = null;
    }

    for( var i = 0; i < this.currentCustomerModel.intPersonaSuples.length; i++ ){
      var objSuple = this.currentCustomerModel.intPersonaSuples[ i ];
      objSuple.accion = this.currentCustomerModel.accion
      objSuple.motivo = this.currentCustomerModel.motivo
    }
    this.saveAplicante(aplicante);
    return true;
  }

   saveAplicante(aplicante) {

    this.loading = true;

    this.deliveryInternalsService.editIntPersona(aplicante).subscribe(
      response => {

        if( response.status == 200 ){
          //var data = response.body;
          //this.showResult = true;
          this.loading = false;          
          this.showAplicanteDetalles = false;
          this.showResult = true;
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadReportResults();
          window.scroll(0, 0); 
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseHoldInternal(this.currentCustomerModel).subscribe();
      },
      error => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseHoldInternal(this.currentCustomerModel).subscribe();
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.",this.title, true, ALERT_TYPE.WARNING);
      }
    );   
    
    return true;
  }


  messageTrackingShow(){
      this.messagetrackingshow = true;
  }

  validationAccionLoad(){

    this.deliveryInternalsService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;
  }

  aplicanteAccionChange(item){

    this.loadingMotivo = true;
    let request = {"parent" : item}

    this.deliveryInternalsService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];
          this.motivosAplicanteList.data = this.motivosAplicanteList.data.filter(m=>m.key != "ENTREGADA");

          let motivo = this.motivosAplicanteList.data.find(a=>a.key == this.currentCustomerModel.motivo);
          if(motivo == null){
            this.currentCustomerModel.motivo = "";
          }
        
          this.motivosAplicanteList.data.sort((a, b)=> a.key.localeCompare(b.key));  

          this.aplicanteMotivoChange(this.currentCustomerModel.motivo);
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        this.loadingMotivo = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title,true);
      }
    );    

    return true;
  }
  


  aplicanteMotivoChange(motivo){
    
    if(this.aplicanteCurrent.accion == "APROBADO LANAS" &&
       motivo == "FECHA PACTADA"){
        this.fechaPactacionDisabled = false;
        this.fechaProxContactoDisabled = true;
    }
    
    if(this.aplicanteCurrent.accion == "APROBADO LANAS" &&
       (motivo == "PACTAR ENTREGA" || motivo == "REPACTAR ENTREGA")){
     this.fechaPactacionDisabled = true;
     this.fechaProxContactoDisabled = false;
 }
  
  }
    
  
  backToResults(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;

    
    if(!Utils.isNullOrEmpty(this.currentCustomerModel.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseHoldInternal(this.currentCustomerModel).subscribe();
    }

    return true;
  }

  public validationsForm(){
  //   this.validations = new Array<any>();

  //   if(this.accionAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.accion) == null){
  //     this.aplicanteCurrent.accion = "";
  //     this.validations.push({"validacion.accion" : "este campos es requerido."})
  //   }  

  //   if(this.motivosAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.motivo) == null){
  //     this.aplicanteCurrent.motivo = "";
  //     this.validations.push({"validacion.motivo" : "este campos es requerido."})
  //   } 


  //   if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.celular))
  //       this.validations.push({"datos_contacto.celular" : "este campos es requerido."})
 
  //   if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal))
  //       this.validations.push({"datos_contacto.email_personal" : "este campos es requerido."})

  //   if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
  //     let rxEmail = new RegExp(Environment.RegExEmail);
  //     if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
  //       this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
  //     }
  //   }  
    
  //   if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
  //     let rxEmail = new RegExp(Environment.RegExEmail);
  //     if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
  //       this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
  //     }
  //   }

  //   if(!this.fechaProxContactoDisabled){

  //       if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_prox_contacto))
  //         this.validations.push({"detalles.fecha_prox_contacto" : "este campos es requerido."});



  //       if(!Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_prox_contacto)){
        
  //           let b = moment(this.aplicanteCurrent.detalles.fecha_prox_contacto, "DD/MM/YYYY");
            
  //           var duration = moment().diff(b, 'days') 

  //           if(duration> 0)       
  //             this.validations.push({"detalles.fecha_prox_contacto" : "Fecha Próx. Contacto debe ser igual o posterior al día de hoy."})
        
        

  //       }

  //       if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.hora_prox_contacto))
  //           this.validations.push({"detalles.hora_prox_contacto" : "campo hora es requerido."});
      
        
  //   }



  //   if(!this.fechaPactacionDisabled){  
  //     if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_pactacion))
  //         this.validations.push({"detalles.fecha_pactacion" : "este campos es requerido."});


       

  //     if(!Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_pactacion)){
      
  //       let b = moment(this.aplicanteCurrent.detalles.fecha_pactacion, "DD/MM/YYYY");
        
  //       var durationDay = moment().diff(b, 'days') 

  //       if(durationDay> 0)       
  //         this.validations.push({"detalles.fecha_pactacion" : "Fecha Pactacion debe ser igual o posterior al día de hoy."})
  //     }   

  // }
  //   return this.validations.length == 0;
  }

  // public validationsMessage(name):string{
  //   if(!this.validationsShow)
  //     return null;

  //   this.validationsForm();

  //   let validation = this.validations.find(v => v[name] != null);

  //   if(validation){
  //     return validation[name];
  //   }
      
  //   return  null;
  // }

  loadVendors(){

    let user = this.sessionStorageService.getCurrentUser();

    if( user.agencia == null ){
      this.vendorsListShow = true;

      this.deliveryInternalsService.getAllPromotores().subscribe(
        response => {
  
          if( response.status == 200 && response.body != null){
  
            this.promotoresList = Object.assign([], response.body);
            
          }else if( response.status == 204 ){
            console.log( "no se encontraron promotores" );
          }else{
            console.log( "Error 1 obteniendo los promotores" );
          }
  
        }, err => {
          console.log( "Error 2 obteniendo los promotores" );
          this.loading = false;
          throw err;
        }
      );
      
    }else{
      this.promotorUser = new Vendor();
      this.promotorUser.id = user.promotor;
      this.promotorUser.name = user.agencia;
      this.promotoresList.push( this.promotorUser );
      this.busquedafiltros.promotor = user.promotor;
      this.vendorsListShow = false;
    }

    
    

  }

  loadProductos(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("PRODUCTOS");
      },
      error => {
        this.setLoadingListsFinish("PRODUCTOS");
      }
    );

    
    

  }

  public disableInputRolConsulta(){
  if (!this.rolConsulta){
    const inputs  = document.getElementsByClassName('form-control');
    const guardarBtn  = document.getElementsByClassName('rolConsultaButton');
    const calendar  = document.getElementsByClassName('p-calendar');
    for(let i=0;i<inputs.length;i++){
      inputs[i].setAttribute("disabled","true")
    }
    for(let i=0;i<guardarBtn.length;i++){
      guardarBtn[i].setAttribute("disabled","true")      
    }
    for(let i=0;i<calendar.length;i++){
      calendar[i].setAttribute("disabled","true")      
    }
  }
}

public rolesAutorization(){
  let rolesSession = this.sessionStorageService.getCurrentUser().roles

  if (rolesSession.includes("AMX_INT_CONSULTA")){
    this.rolConsulta=false
  }

}


  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {

        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        //info: false,
        lengthChange: false,
        responsive: true,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          {
            targets: 4,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
          {
            targets: 5,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
        ],        
        language: Environment.DataTableLanguageSetting
        // language:{
        //   url:'//cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json',
        //   info: "Página _PAGE_ de _PAGES_"
        // }
       });
  
       $(".result-tlmk .row").css("overflow-x", "auto");

    });

  }  
  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.resultsData.forEach(item => {   
      let objRow: any = new Object;
      objRow["NOMBRE Y APELLIDO"] = item.intSocioData.cardName;
      objRow.CUIL = item.intSocioData.cuil;
      objRow.DNI = item.intSocioData.dni;
      objRow.PCN = item.pcn;
      objRow.FECHA = item.fechaArchivo;
      objRow["ULTIMA MODIF"] = item.fechaModificacion;
      objRow.PRODUCTO = item.productoObj.data.prod+ " - " +item.productoObj.value;
      objRow.ESTADO = item.motivo;

      for( var i = 0; i < item.intPersonaSuples.length; i++ ){
        let obSup = item.intPersonaSuples[ i ];
        objRow["dni_sup_"+( i+1 )] = obSup.docNumSuple;
        objRow["nombre_sup_"+( i+1 )] = ( !Utils.isNullOrEmpty( obSup.nombreCompleto ) ) ? obSup.nombreCompleto : obSup.nombre1 + " "+obSup.apellido ;
        objRow["estado_sup_"+( i+1 )] = ( obSup.denegado ) ? "Denegado" : "Aprobado";
        objRow["motivo_sup_"+( i+1 )] = obSup.motivo;
      }

      reportDataExport.push(objRow);
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  }  

  setCalendarLocale(){ 
    this.es = Environment.CalendarLanguageSetting
  }

  
  public  setupTootip(){
    $(function () {
        (<any>$('[data-toggle="tooltip"]')).tooltip()
    })

    return true;
  }

  refactorObservationPaste(event:ClipboardEvent){ 
    //this.aplicanteCurrent.observaciones.observacion=this.sanitizedText(this.aplicanteCurrent.observaciones.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

  //  refactorCp(){ 
  //   this.aplicanteCurrent.datos_personales.codigo_postal=this.sanitizedTextCp(this.aplicanteCurrent.datos_personales.codigo_postal); 
  //    }
 sanitizedTextCp(text:string):string{
   return text.replace(/[^0-9]/g,'')
 }

 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 
}

  listProvinceLoad(){

    this.deliveryInternalsService.getListProvince().subscribe(
    response => {
      if( response.status == 200 && response.body != null){
        var data = response.body;
        this.provinceData = data['data']
  
      }else if( response.status == 204 ){
        this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
      }else{
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
      }
  
      this.loading = false;
      },
    );
  }

}
