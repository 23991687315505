import { AbstractType, Component, OnInit } from '@angular/core';

import { CRUD, Environment } from '../../config/environment';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';

import { NewAccountsService } from '../../services/newAccounts/newAccounts.service';
import { AplicantModel, DatosPersonalesModel, 
  DatosContactoModel, DatosLaboralesModel,
  DireccionModel, TelefonoModel, ObservacionesModel,
  EntregaDocumentacionModel, ObservacionesTackingModel, 
  ValidacionModel, AplicanteAdicionalModel, BusquedaFiltrosModel
 } from 'src/app/models/aplicante.model';

import {  TiposDocumentosList, VinculosList,
   NacionalidadesList, EstadosCivilList, ProvinciasList,
   TiposViviendasList, EducacionNivleslList, ProfesionesList,
   TrabajosTipolList, ProductosList, CargoslList, GenerosList,
   AccionAplicanteList, MotivosAplicanteList, SourceCodesList} 
   from 'src/app/models/lists.model';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import 'datatables.net';
import { CommonDataService } from 'src/app/services/commons/commondata.service';

import * as moment from 'moment';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Utils } from 'src/app/commons/utils';
import { Roles } from 'src/app/auth/models/roles.static';
import { utils } from 'protractor';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { BaseComponent } from '../shared/base.component';
import { AplicantDetailModel } from '../applicantDetail/models/models';
import { Console } from 'console';

@Component({
  selector: 'app-newAccounts',
  templateUrl: './newAccounts.component.html',
  styleUrls: ['./newAccounts.component.css']
})

export class NewAccountsComponent  extends BaseComponent  implements OnInit {

  public title: string = "ADMINISTRAR CUENTAS NUEVAS";
  public es: any;
  public tablereports = null;
  public showResult : boolean;
  public showAplicanteDetalles : boolean;
  public showAdicionalesEditar : boolean;
  
  
  
  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  public loading: boolean;
  public loadingMotivo: boolean;
  

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public resultsData = [];
  public aplicanteCurrent: AplicantModel = new AplicantModel;
  public aplicanteCurrentBk: AplicantModel = new AplicantModel;
  public adicionalCurrent: AplicanteAdicionalModel = new AplicanteAdicionalModel;
  
  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public validationsAdicional: Array<any> = new Array<any>();
  public validationsAdicionalShow: boolean; 

  dateinfo : Date;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;

  public reevaluarEnabled: boolean = false;  
  public recalcularEnabled: boolean = true;  
  public reevaluarDias: number = null;  

  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();
  
  public generosList: GenerosList = new GenerosList();
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public cargosList: CargoslList = new CargoslList();
  public buttonSave:boolean = true;

  public sourcecodesList: SourceCodesList = new SourceCodesList();
  public sourcecodesListForApplicant: Array<any> = new Array<any>();
  
  public parametersList = { data: new Array<any>()};  

  public openWindow : boolean = false;
  public dataResult :  AplicantDetailModel;
  public recalculoRequest : any;
  public limiteNuevo: boolean = false;
  public productsApplicationSource: any = {data:[]};
  public rolConsulta : boolean=true;

  constructor(private commonDataService: CommonDataService,
              private newAccountsService: NewAccountsService, 
              private sessionStorageService: SessionStorageService) { 
              
                super();
                this.sessionStorageService.rolValidate("newaccounts");
  }

  ngOnInit() {
    this.setCalendarLocale();
    this.validationAccionLoad();
    this.profesionesLoad();
    this.loadProductos();
    this.loadSourcecodes();
    this.loadParameters();
    this.rolesAutorization();
    
    this.busquedafiltros.producto = "";    
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }


  loadReportResults(){

    if(Utils.dateIsValid(this.busquedafiltros.fechaDesde) &&
      Utils.dateIsValid(this.busquedafiltros.fechaHasta)){
      let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fechaDesde, this.busquedafiltros.fechaHasta, "days");

     if(cap_days < 0){
      this.alertMessage = new AlertModel("Fecha: Desde debe ser mayor que Hasta.",this.title,true);
      return;
      }  
    }

    this.loading = true;
    this.loadReportRequest();
  }

  loadReportRequest(){

    let request = { 
      "matriz": this.sessionStorageService.getCurrentUser()?.matriz,
      "dni": Utils.isNullOrEmpty(this.busquedafiltros.dni) ? null : this.busquedafiltros.dni, 
      "fecha": Utils.isNullOrEmpty(this.busquedafiltros.fecha) ? null : this.busquedafiltros.fecha, 
      "nombreapellido": Utils.isNullOrEmpty(this.busquedafiltros.nombre) ? null : this.busquedafiltros.nombre, 
      "pcn": Utils.isNullOrEmpty(this.busquedafiltros.pcn) ? null : this.busquedafiltros.pcn, 
      "producto": Utils.isNullOrEmpty(this.busquedafiltros.producto) ? null : this.busquedafiltros.producto,
      "fechaDesde": Utils.isNullOrEmpty(this.busquedafiltros.fechaDesde) ? null : this.busquedafiltros.fechaDesde,
      "fechaHasta": Utils.isNullOrEmpty(this.busquedafiltros.fechaHasta) ? null : this.busquedafiltros.fechaHasta
    };    

    this.newAccountsService.getApplicantsReportByArea(request).subscribe(
      data => {
        if (data['data']) {
          this.resultsData = data['data'];

          this.resultsData.forEach(i=>{
            i.producto = this.productosList.data.find(p=>p.key == (i.producto??""))?.value ?? "";
          })  

          this.setupTable();
        }
        else {
          console.error(data);
        }
      },
      error => {
        console.error(error);
      }
    );

    return true;
  }

  showAplicante(pcn:string){
      this.loading = true;
      this.reevaluarEnabled = false;
      
      let request = {
         "pcn": pcn
      };

      this.newAccountsService.getAplicanteDetalle(request).subscribe(
        data => {

          if (data && data['code'] == "100"){
            this.alertMessage = new AlertModel(data['message'] , this.title, true);
            this.loading = false;
            return;
          }

          if (data && data["data"]) {

            if(data["data"].length == 0){
              this.loading = false;
              this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, "ERROR");   
              return;
            }

        
            if(!Utils.dateIsValid(data["data"][0].datos_personales.fecha_nacimiento)){
              
              if(Utils.dateIsValid(data["data"][0].datos_personales.fecha_nacimiento.split(" ")[0])){
                data["data"][0].datos_personales.fecha_nacimiento = data["data"][0].datos_personales.fecha_nacimiento.split(" ")[0];
              }else
                data["data"][0].datos_personales.fecha_nacimiento = "";
              
            }
            

            this.aplicanteCurrent = Object.assign(this.aplicanteCurrent,data["data"][0]);
            this.aplicanteCurrent.observaciones.observacion = "";
            if( this.aplicanteCurrent.observaciones_tracking.length ){
              this.aplicanteCurrent.observaciones_tracking.sort(function(a, b) {
                var textA = a.fecha;
                var textB = b.fecha;
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
              });
            }

            if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.estado_civil)){
              this.aplicanteCurrent.datos_personales.estado_civil = "NA";
            }

            let capturaDateIsValid = Utils.dateIsValid(this.aplicanteCurrent.informacion_interna.captura_inicio_fecha);
            let capturaDateIsValidFormat = Utils.dateIsValidFormat(this.aplicanteCurrent.informacion_interna.captura_inicio_fecha.split(" ")[0], "YYYY-MM-DD");

            
            if(capturaDateIsValid || capturaDateIsValidFormat){
              
              let reevaluarDiasApplicant = 0;
              
              if(capturaDateIsValid) {
                reevaluarDiasApplicant = Utils.dateDiff(this.aplicanteCurrent.informacion_interna.captura_inicio_fecha, "days");
              } else{
                reevaluarDiasApplicant = Utils.dateDiffFormat(this.aplicanteCurrent.informacion_interna.captura_inicio_fecha.split(" ")[0], "days", "YYYY-MM-DD");
              }
                            

              if(this.reevaluarDias != null){
                this.buttonSave = !(reevaluarDiasApplicant > this.reevaluarDias);
                this.reevaluarEnabled = (reevaluarDiasApplicant > this.reevaluarDias)
                this.recalcularEnabled = !this.reevaluarEnabled;
                this.aplicanteCurrent.datos_precheck.vigenciaPrecheck = reevaluarDiasApplicant.toString();
                  
              } else{
                this.aplicanteCurrent.datos_precheck.vigenciaPrecheck = reevaluarDiasApplicant.toString();
                this.buttonSave = true;
              }                        

            }else {
              this.buttonSave = true;
            }

            let accion = this.accionAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.accion);
            
            if(accion){
              this.aplicanteAccionChange(this.aplicanteCurrent.validacion.accion);
            } else{
              
              this.accionAplicanteList.data.push({
                          "key":this.aplicanteCurrent.validacion.accion,
                          "value":this.aplicanteCurrent.validacion.accion });

              this.motivosAplicanteList.data.push({
                          "key":this.aplicanteCurrent.validacion.motivo,
                          "value":this.aplicanteCurrent.validacion.motivo,
                          "parent":this.aplicanteCurrent.validacion.accion });                          
            }     

            this.aplicanteCurrent.adicionales = this.aplicanteCurrent.adicionales.filter(a=>{

              if(!Utils.dateIsValid(a.fecha_nacimiento)){
                a.fecha_nacimiento = null;
              }

              return !Utils.isNullOrEmpty(a.numero_documento);
            });            

            if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_precheck.ingresoReal )){
              
              this.aplicanteCurrent.datos_precheck.ingresoReal =  this.aplicanteCurrent.datos_precheck.incomePercentil;

            }else{

              let ingresoReal = parseInt(this.aplicanteCurrent.datos_precheck.ingresoReal);

              if(ingresoReal == 0 || isNaN(ingresoReal)){
                this.aplicanteCurrent.datos_precheck.ingresoReal =  this.aplicanteCurrent.datos_precheck.incomePercentil;
              }
            }            


            this.aplicanteCurrent.detalles.cantidad_suples = this.aplicanteCurrent.adicionales.length.toString();
            this.aplicanteCurrent.datos_personales.nombre_completo = this.aplicanteCurrent.datos_personales.nombre_completo.trim();
            this.aplicanteCurrentBk = JSON.parse(JSON.stringify(this.aplicanteCurrent));
              
            this.sourcecodesForApplicant();

            if(this.aplicanteCurrent.datos_precheck.limiteFinalOld != null && this.aplicanteCurrent.datos_precheck.limiteFinalOld.length > 0){
              this.limiteNuevo = true;
            }
           
            this.validationsShow = false;
            this.showAplicanteDetalles = true;
            this.showResult = false
            this.loading = false;
            this.refactorCp();
            this.setupTootip();            
            window.scroll(0, 0);
            this.loadDataRolConsulta(pcn)
            this.commonDataService.pingApplicantHoldTimerStart(pcn);
          }
          else {
            console.error(data);
          }
        },
        error => {
          console.error(error);
        }
      );      
      
    return true;
  
  }

  public loadDataRolConsulta(pcn:string){
    let request = {
      "pcn" : pcn
    }
    this.newAccountsService.getAplicanteDetalle(request).subscribe(
      data => {
        this.productsApplicationSource.data = data["data"]     
        if(this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent) == null)
          this.disableInputRolconsulta()
        },
      error => console.error(error));   
  }

  public disableInputRolconsulta(){
    
  if (!this.rolConsulta){
    const inputs  = document.getElementsByClassName('form-control');
    const guardarBtn  = document.getElementsByClassName('rolConsultaButton');
    for(let i=0;i<inputs.length;i++){
      inputs[i].setAttribute("disabled","true")
    }
    for(let i=0;i<guardarBtn.length;i++){
      guardarBtn[i].setAttribute("disabled","true")      
    }
  }
}

public rolesAutorization(){
  let rolesSession = this.sessionStorageService.getCurrentUser().roles
    console.log(rolesSession)
  if (rolesSession.includes("AMX_CONSULTA")){
    this.rolConsulta=false
  }

}



  listAplicanteDireccinesTelefonos(origen:string){

    let data = Array<any>();
    let direciones = this.aplicanteCurrent.direcciones.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let telefonos = this.aplicanteCurrent.telefonos.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let length = (direciones.length >= telefonos.length) ? direciones.length : telefonos.length;

    for(let i = 0; i < length; i++){
      data.push({
        "direccion" : direciones[i] != null ? direciones[i].direcciones : "",
        "telefono" : telefonos[i] != null ? telefonos[i].telefono : "",
      });   
    }
   
    return data;
  }

  confirmSave(){

    try{
      if(!this.rolConsulta){
        this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
        return;
      } 
      if(!this.validationsForm()){
        this.validationsShow = true;
        this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
        return;
      }
    }catch{}
    
    this.alertConfirmEvent = this.onConfirmEvent;
    this.alertMessage = new AlertModel("Confirma que desea registrar la información?",this.title,true, "CONFIRM");	

  }

  	
  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveAplicanteData();
    }
  }

  saveAplicanteData(){

    let aplicante: any = new Object;

    aplicante.usuario = this.sessionStorageService.getCurrentUser()?.username;
    aplicante.promotor = this.sessionStorageService.getCurrentUser()?.promotor;

    aplicante.solicitud_tarjeta = this.aplicanteCurrent.solicitud_tarjeta;
    aplicante.datos_precheck = this.aplicanteCurrent.datos_precheck;
    aplicante.datos_personales = this.aplicanteCurrent.datos_personales;
    aplicante.datos_contacto = this.aplicanteCurrent.datos_contacto;
    aplicante.datos_laborales = this.aplicanteCurrent.datos_laborales;
    aplicante.detalles = this.aplicanteCurrent.detalles;    
    aplicante.adicionales = this.aplicanteCurrent.adicionales;
    aplicante.observaciones = this.aplicanteCurrent.observaciones;
    aplicante.validacion = this.aplicanteCurrent.validacion;    

    this.saveAplicante(aplicante);
  }

  saveAplicante(aplicante) {

    this.loading = true;

    this.newAccountsService.saveApplicantDataByArea(aplicante).subscribe(
      data => {
        if (data != null && data['code'] && data['code'] == "0") {

          this.loading = false;          
          this.showAplicanteDetalles = false;
          this.showResult = true;                    
          
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadReportResults();
          window.scroll(0, 0);   
          
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
		
      },
      error => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
		        
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
      }
    );
    
    return true;
  }


  messageTrackingShow(){
      this.messagetrackingshow = true;
  }



  adicionalesList(){

    let adicionales = this.aplicanteCurrent.adicionales.filter((a)=>{

      return a.crud != CRUD.DELETE;
    });

    return adicionales;
  }
  
  adicionalesEditar(dni:any) {

    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

      return a.numero_documento == dni;
    });

    let adicionalBk = this.aplicanteCurrentBk.adicionales.find((a)=>{
      return a.numero_documento == dni;
    });

    this.adicionalCurrent  = Object.assign({}, adicional);
    this.adicionalCurrent.crud = adicionalBk ? CRUD.UPDATE : CRUD.CREATE;

    this.showAdicionalesEditar = true;
    
    return true;
  }

  adicionalesNuevo() {

    let adicionales = this.aplicanteCurrent.adicionales.filter(a=>a.crud != CRUD.DELETE);
    if(adicionales.length >= 5){
      this.alertMessage = new AlertModel("Solo se permiten hasta 5 adicionales.", this.title,true, ALERT_TYPE.INFO);	
    }

    this.adicionalCurrent  = new  AplicanteAdicionalModel();
    this.adicionalCurrent.crud = CRUD.CREATE;

    this.showAdicionalesEditar = true;
 }

  adicionalesCancelar(){

    this.showAdicionalesEditar = false;
    this.adicionalCurrent = null;
  }

  adicionalesGuardar(){

    if(!this.validationsAdcionalForm()){
      this.validationsAdicionalShow = true;
      return;
    }

    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

      return a.numero_documento == this.adicionalCurrent.numero_documento;
    });

    let adicionalcount = this.aplicanteCurrent.adicionales.filter((a)=>{
      return a.numero_documento == this.adicionalCurrent.numero_documento;
    });

    if(adicional==null && adicionalcount.length >= 1){
      this.alertMessage = new AlertModel("Ya existe un adicional con el mismo número documento.", this.title,true, ALERT_TYPE.INFO);	
      return false;      
    }     

    if(adicional) {
        let index = this.aplicanteCurrent.adicionales.indexOf(adicional);

        this.aplicanteCurrent.adicionales[index] = Object.assign({},  this.adicionalCurrent );
        this.aplicanteCurrent.adicionales[index].usuario = this.sessionStorageService.getCurrentUser().username;
        
    }else{
      this.adicionalCurrent.usuario = this.sessionStorageService.getCurrentUser().username;
      this.aplicanteCurrent.adicionales.push(this.adicionalCurrent);
      
    }

    this.showAdicionalesEditar = false;

  }

  adicionalesBorrarConfirm(confirm:any){

    if(confirm.confirm){
      let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

        return a.numero_documento == confirm.data;
      });
  
     let index =  this.aplicanteCurrent.adicionales.indexOf(adicional);

     this.aplicanteCurrent.adicionales[index].crud = CRUD.DELETE;;
    }
  }
  
  adicionalesBorrar(dni:any){

    this.alertConfirmEvent = this.adicionalesBorrarConfirm;
    this.alertMessage = new AlertModel("Esta acción eliminará el siguiente registro: " + dni + "\nEsta seguro de continuar?",this.title,true, "CONFIRM", dni);	
        
  }

  validationAccionLoad(){

    this.newAccountsService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;
  }

  
  profesionesLoad(){

    this.commonDataService.getProfesiones().subscribe(
      data => {
        if (data['data']){
          this.profesionesList.data = data['data'];
          this.profesionesList.data.sort((a, b)=> a.value?.localeCompare(b.value));      
        }        
        else 
          console.error(data);          
        
      },
      error => console.error(error)
    );

    return true;

  }

  loadProductos(){

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    );

    
    

  }

  loadParameters(){

    this.commonDataService.getParameters().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.parametersList.data = Object.assign([], data['data']);

          let paramReevaluarDias = this.parametersList.data
            .find(p=>p.nombre == "N DIAS REEVALUAR" 
                  && p.codigo == "REEVALUAR"
                  && p.activo == true);

          if(paramReevaluarDias != null){
            this.reevaluarDias = parseInt(paramReevaluarDias.valor);
          }

        }
        else {
          console.error(data);
          this.reevaluarDias = null;
        }
        
      },
      error => {
        console.error(error);
      }
    ); 

  }

  loadSourcecodes(){

    this.commonDataService.getSourcecodes().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.sourcecodesList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    ); 

  }

  sourcecodesForApplicant(){

    this.sourcecodesListForApplicant = [];
    let  source =  this.sourcecodesList.data.find(s=>s.source_code_completo == this.aplicanteCurrent.solicitud_tarjeta.application_source);

    if(source != null){
        this.sourcecodesListForApplicant.push({key:source.delivery_1, value: `Delivery Code 1 [${source.delivery_1}]`});
        this.sourcecodesListForApplicant.push({key:source.delivery_2, value: `Delivery Code 2 [${source.delivery_2}]`});

    }

}

  aplicanteAccionChange(item){

    this.loadingMotivo = true;
    let request = {"parent" : item}

    this.newAccountsService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];

          let motivo = this.motivosAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.motivo);
          if(motivo == null){
            this.aplicanteCurrent.validacion.motivo = "";
          }


          this.motivosAplicanteList.data.sort((a, b)=> a.key?.localeCompare(b.key));  
          

        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        this.loadingMotivo = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
  }


  backToResults(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;

    
    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.solicitud_tarjeta.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
    }

  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }
    

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  public reevaluar(){
    this.loading = true;

    let request = {
      "pcn" : this.aplicanteCurrent.solicitud_tarjeta.pcn,
      "cuil":true
    };
    
    
    this.newAccountsService.getApplicantReevaluation(request).subscribe(
      data => { 
        
        if(data["reevaluation"] && data["code"] == "0") {
          let response : any  = data;

          if(response.reevaluation.validation == "OK") {

            let ingresoReal = this.aplicanteCurrent.datos_precheck?.ingresoReal ??"0";
            
            this.aplicanteCurrent.datos_precheck = response.datos_precheck;
            this.aplicanteCurrent.direcciones = response.direcciones;
            this.aplicanteCurrent.telefonos = response.telefonos;

            this.aplicanteCurrent.datos_precheck.limiteFinal = response.datos_precheck.limiteFinal;

            if(ingresoReal != "0")
              this.aplicanteCurrent.datos_precheck.ingresoReal = ingresoReal;

            this.buttonSave = true;
            this.alertMessage =  new  AlertModel("APROBADO: "+ response.reevaluation.message, "REEVALUAR", true);
          } else if(response.reevaluation.validation == "NOOK"){
            
            this.alertConfirmEvent = ()=>{
              this.buttonSave = true;
              this.loading = false;          
              this.showAplicanteDetalles = false;
              this.showResult = true;

              this.loadReportResults();
              window.scroll(0, 0);   
            };

            this.buttonSave = false;
            this.alertMessage =  new  AlertModel("RECHAZADO: " + response.reevaluation.message, "REEVALUAR", true, ALERT_TYPE.CONFIRM, null, false);
          }else{
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
          }

          this.aplicanteCurrent.datos_precheck.vigenciaPrecheck = "0";
          this.reevaluarEnabled = false;
          this.recalcularEnabled = true;

            
        }else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }

        this.loading = false;

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      });  
          

  }

  validationsForm(){
    this.validations = new Array<any>();


    if(this.accionAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.accion) == null){
      this.aplicanteCurrent.validacion.accion = "";
      this.validations.push({"validacion.accion" : "este campo es requerido."})
    }  

    if(this.motivosAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.motivo) == null){
      this.aplicanteCurrent.validacion.motivo = "";
      this.validations.push({"validacion.motivo" : "este campo es requerido."})
    } 

      
    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.nombre_primero))
       this.validations.push({"datos_personales.nombre_primero" : "este campo es requerido."})

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.apellido_primero))
       this.validations.push({"datos_personales.apellido_primero" : "este campo es requerido."})       

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.nombre_completo ))
       this.validations.push({"datos_personales.nombre_completo" : "este campo es requerido."})

    if(this.nacionalidadesList .data.find(v=>v.key==this.aplicanteCurrent.datos_personales.nacionalidad) == null){
        this.aplicanteCurrent.datos_personales.nacionalidad = "";
        this.validations.push({"datos_personales.nacionalidad" : "este campo es requerido."})
    }  

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.fecha_nacimiento))
       this.validations.push({"datos_personales.fecha_nacimiento" : "este campo es requerido."})


    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.fecha_nacimiento)){
      
        let b = moment(this.aplicanteCurrent.datos_personales.fecha_nacimiento, "DD/MM/YYYY");
        
        if(moment().diff(b, 'years') < 18)       
          this.validations.push({"datos_personales.fecha_nacimiento" : "no se permite menor a 18 años"})
    }        
       
    if(this.provinciasList .data.find(v=>v.key==this.aplicanteCurrent.datos_personales.provincia) == null){
        this.aplicanteCurrent.datos_personales.provincia = "";
        this.validations.push({"datos_personales.provincia" : "este campo es requerido."})
    }  

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.localidad))
       this.validations.push({"datos_personales.localidad" : "este campo es requerido."})

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.codigo_postal))
       this.validations.push({"datos_personales.codigo_postal" : "este campo es requerido."})

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.calle))
       this.validations.push({"datos_personales.calle" : "este campo es requerido."})

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_personales.calle_nro))
       this.validations.push({"datos_personales.calle_nro" : "este campo es requerido."})

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.celular))
       this.validations.push({"datos_contacto.celular" : "este campo es requerido."})
    
    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal))
       this.validations.push({"datos_contacto.email_personal" : "este campo es requerido."})

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
      let rxEmail = new RegExp(Environment.RegExEmail);
      if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
        this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
      }
    }

    if(this.profesionesList.data.find(v=>v.key==this.aplicanteCurrent.datos_laborales.profesion) == null){
      this.aplicanteCurrent.datos_laborales.profesion = "";
      this.validations.push({"datos_laborales.profesion" : "este campo es requerido."})
    }  


    if(this.trabajosTipolList.data.find(v=>v.key==this.aplicanteCurrent.datos_laborales.tipo_trabajador) == null){
      this.aplicanteCurrent.datos_laborales.tipo_trabajador = "";
      this.validations.push({"datos_laborales.tipo_trabajador" : "este campo es requerido."})
    }  

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_laborales.antiguedad))
       this.validations.push({"datos_laborales.antiguedad" : "este campo es requerido."});

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_precheck.ingresoReal)){
       this.validations.push({"datos_precheck.ingresoReal" : "este campo es requerido."});
    }else{
      let ingresoReal = parseInt(this.aplicanteCurrent.datos_precheck.ingresoReal);

      if(ingresoReal <= 0){
        this.validations.push({"datos_precheck.ingresoReal" : "este campo debe ser mayor a cero."});
      }
    }

       
    if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.ciclo))
      this.validations.push({"detalles.ciclo" : "este campo es requerido."});

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.delivery_code))
      this.validations.push({"detalles.delivery_code" : "este campo es requerido."});

    return this.validations.length == 0;
  }

  public validationsMessage(name):string{
    if(!this.validationsShow)
      return null;

    this.validationsForm();

    let validation = this.validations.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  validationsAdcionalForm(){
    this.validationsAdicional = new Array<any>();


    if(this.tiposDocumentosList.data.find(v=>v.key==this.adicionalCurrent.tipo_documento) == null){
      this.adicionalCurrent.tipo_documento = "";
      this.validationsAdicional.push({"adicionalCurrent.tipo_documento" : "este campo es requerido."})
    }  

    if(Utils.isNullOrEmpty(this.adicionalCurrent.numero_documento))
             this.validationsAdicional.push({"adicionalCurrent.numero_documento" : "este campo es requerido."});

    
    if(Utils.isNullOrEmpty(this.adicionalCurrent.fecha_nacimiento))
        this.validationsAdicional.push({"adicionalCurrent.fecha_nacimiento" : "este campo es requerido."});


    if(!Utils.isNullOrEmpty(this.adicionalCurrent.fecha_nacimiento)){

      let b = moment(this.adicionalCurrent.fecha_nacimiento, "DD/MM/YYYY");

      if(moment().diff(b, 'years') < 14)       
        this.validationsAdicional.push({"adicionalCurrent.fecha_nacimiento" : "no se permite menor a 14 años"})
    }         
    
    if(Utils.isNullOrEmpty(this.adicionalCurrent.nombre_completo))
        this.validationsAdicional.push({"adicionalCurrent.nombre_completo" : "este campo es requerido."});

    if(Utils.isNullOrEmpty(this.adicionalCurrent.nombre_primero))
        this.validationsAdicional.push({"adicionalCurrent.nombre_primero" : "este campo es requerido."});


    if(Utils.isNullOrEmpty(this.adicionalCurrent.apellido_primero))
        this.validationsAdicional.push({"adicionalCurrent.apellido_primero" : "este campo es requerido."});



    if(this.vinculosList.data.find(v=>v.key==this.adicionalCurrent.vinculo) == null){
      this.adicionalCurrent.vinculo = "";
      this.validationsAdicional.push({"adicionalCurrent.vinculo" : "este campo es requerido."})
    }  


    if(this.generosList.data.find(v=>v.key==this.adicionalCurrent.genero) == null){
      this.adicionalCurrent.genero = "";
      this.validationsAdicional.push({"adicionalCurrent.genero" : "este campo es requerido."})
    }      
    
    

    return this.validationsAdicional.length == 0;
  }

  public validationsAdcionalMessage(name):string{
    if(!this.validationsAdicionalShow)
      return null;

    this.validationsAdcionalForm();

    let validation = this.validationsAdicional.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.resultsData.forEach(item => {      
      reportDataExport.push({
        "NOMBRE Y APELLIDO":item.nombreapellido,
        "CUIL":item.cuit,
        "DNI":item.dni,
        "PCN":item.pcn,
        "FECHA":item.fecha,
        "ULTIMA MODIF":item.fecha_modificacion,
        "PRODUCTO":item.producto,
        "ESTADO":item.estado,
              
      });
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  } 
  
  public recalcular(){
    this.loading = true;

    let request = {
      "pcn" : this.aplicanteCurrent.solicitud_tarjeta.pcn,
      "ingresoLoc" : this.recalculoRequest,
      "documento" : this.aplicanteCurrent.datos_personales.numero_documento,
      "cuil":true
    };
    
    
    this.newAccountsService.getApplicantRecalculation(request).subscribe(
      data => { 
        let dataMessage = data['message'];
        if(data['data'] && data['code'] == "0") {
         let response : any = data;
          this.dataResult = data['data'][0];
        
          if(this.dataResult != undefined && this.dataResult.limiteFinal) {

            

            this.aplicanteCurrent.datos_precheck.limiteFinal = this.dataResult.limiteFinal;
            this.aplicanteCurrent.datos_precheck.limiteFinalOld =  this.dataResult.limiteFinalOld;
            this.aplicanteCurrent.datos_precheck.ingresoReal = this.recalculoRequest
            this.loading = false;
            this.buttonSave = true;
            this.limiteNuevo = true;
           
            this.alertMessage =  new  AlertModel(dataMessage,null,true,"SUCCESS");
            this.closeRecalculate()
            $('.modal-backdrop').remove();
          } else {
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
          }
  
        }else{
          this.alertMessage = new AlertModel(dataMessage,null,true,"WARNING");
        }

        this.loading = false;

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      });  
          

  }

  openRecalculate(){
    this.recalculoRequest = "";
    this.openWindow = true;
  }

  closeRecalculate(){
    this.openWindow = false;
  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.aplicanteCurrent.observaciones.observacion=this.sanitizedText(this.aplicanteCurrent.observaciones.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorCp(){ 
    this.aplicanteCurrent.datos_personales.codigo_postal=this.sanitizedTextCp(this.aplicanteCurrent.datos_personales.codigo_postal); 
     }
 sanitizedTextCp(text:string):string{
   return text.replace(/[^0-9]/g,'')
 }
 
 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 
}

public onKey(e){
  if(e.code == "Enter"){
    this.loadReportResults();
  }
}
 
}
