import { AbstractType, Component, OnInit } from '@angular/core';
import { AlertComponent, AlertModel } from '../shared/alert/alert.component';

import { ReportApplicantsSearchModel } from './models/models';

import { Environment } from 'src/app/config/environment';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Utils } from 'src/app/commons/utils';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { ProductoFilterModel, VendorFilterModel } from '../precheck/models/models';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../shared/base.component';
import { ReportApplicantsService } from 'src/app/services/reportApplicants/report-applicants.service';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
declare var $ : any;

@Component({
    selector: 'app-report-applicants',
    templateUrl: './report-applicants.component.html',
    styleUrls: ['./report-applicants.component.css']
  })

  export class ReportApplicantsComponent   extends BaseComponent implements OnInit {
    
    public vendorsList :Array<VendorFilterModel> = new Array<VendorFilterModel>(); 
    public productsList :Array<ProductoFilterModel> = new Array<ProductoFilterModel>(); 
    public loading: boolean;
    public displayError: boolean;
    public alertMessage: AlertModel = new AlertModel;
    public title: string = "Reporte de Cuentas Aprobadas";
    public titleFile: string = "Reporte_de_Cuentas_Aprobadas";
    public es: any;
    public busquedafiltros: ReportApplicantsSearchModel = new ReportApplicantsSearchModel();
    public showResult : boolean;
    public resultData = [];
    protected loadingListsBehavior: BehaviorSubject<any>= new BehaviorSubject<any>(null);
    public loadingLists: Array<any> = [
      {"load":"VENDORS", "finish": false},
      {"load":"ESTADOS", "finish": false},
      {"load":"PRODUCTOS", "finish": false}
      
    ];
    public tablereports = null;

    constructor(private commonDataService: CommonDataService,
      private sessionStorageService: SessionStorageService,
        private excelExportService: ExcelExportService,
        private reportApplicantsService: ReportApplicantsService) { 
    
          
        super();
        //this.sessionStorageService.rolValidate("normalizePatterns");
        this.sessionStorageService.rolValidate("reportApplicants");

        }

    ngOnInit(): void {
     
      this.busquedafiltros.promotor ="";
      this.busquedafiltros.producto ="";
      this.loadVendors();
      this.loadProducts();
      this.setCalendarLocale(); 
    }
    setCalendarLocale(){
      this.es = Environment.CalendarLanguageSetting;
    }

    setLoadingListsFinish(loadname){

      this.loadingLists.forEach(i=>{
        if(i.load == loadname){
          i.finish = true;
          this.loadingListsBehavior.next(null);
        }
  
      })
    }

    loadVendors(){

        if(Utils.storageLoad("vendors-rpta")){
          this.vendorsList = Utils.storageLoad("vendors-rpta");
          this.setLoadingListsFinish("VENDORS");
          return;
        }
    
        this.reportApplicantsService.getVendorsList().subscribe(
          data => {
            if (data['data']) {
              
              this.vendorsList = Object.assign(this.vendorsList, data['data']);
          
              this.vendorsList.sort((a, b)=> a.name.localeCompare(b.name));  
              
              Utils.storageSave("vendors-rpta",  this.vendorsList);  
            }
            else {
              console.error(data);
            }
    
            this.setLoadingListsFinish("VENDORS")
          },
          error => {
            this.setLoadingListsFinish("VENDORS")
            console.error(error);
          }
        );
        this.loading = false;
      }

      loadProducts(){

        if(Utils.storageLoad("products-rpta")){
          this.productsList = Utils.storageLoad("products-rpta");
          this.setLoadingListsFinish("PRODUCTOS");
          return;
        }
    
        this.commonDataService.getProductos().subscribe(
          data => {
            if (data['data']) {
              this.productsList = Object.assign(this.productsList, data['data']);
          
              this.productsList.sort((a, b)=> a.value.localeCompare(b.value));  
              Utils.storageSave("products-rpta",  this.productsList);  
            }
            else {
              console.error(data);
            }
    
            this.setLoadingListsFinish("PRODUCTOS")
          },
          error => {
            this.setLoadingListsFinish("PRODUCTOS")
            console.error(error);
          }
        );
        this.loading = false;
      }


      loadResultData(){

        this.loading = true;
    
        let request = { 
          "fecha_procesamiento_fin": Utils.isNullOrEmpty(this.busquedafiltros.fecha_procesamiento_fin) ? null : this.busquedafiltros.fecha_procesamiento_fin, 
          "fecha_procesamiento_inicio": Utils.isNullOrEmpty(this.busquedafiltros.fecha_procesamiento_inicio) ? null : this.busquedafiltros.fecha_procesamiento_inicio, 
          "promotor": Utils.isNullOrEmpty(this.busquedafiltros.promotor) ? null : this.busquedafiltros.promotor,
          "producto": Utils.isNullOrEmpty(this.busquedafiltros.producto) ? null : this.busquedafiltros.producto,
        };  
        this.reportApplicantsService.getData(request).subscribe(
          data => {
            if (data['data']) {

              this.resultData = data['data'];
    
              this.setupTable();             
    
            }
            else {
              this.loading = false;
              console.error(data);
              this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
            }
          },
          error => {
            this.loading = false;
            console.error(error);
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          }

        );

      }

      setupTable(){

        this.showResult = true;
    
        if (this.tablereports != null) {
          this.tablereports.clear().destroy();
        } 
        
        $(document).ready( () => {
            this.tablereports =   $("#result-table").DataTable({
    
            searching: true, 
            paging: true, 
            
            lengthChange: false,
            pageLength: 10,
            pagingType: 'full_numbers',
            columnDefs: [
              { targets: 'no-sort', orderable: false }
            ],    
            deferRender: true,
            language: Environment.DataTableLanguageSetting,
            initComplete: (setting, json) => {
              this.loading = false;
            }
          });
        });
    
      }

    exportar (){

      let reportDataExport = new Array<any>();
      this.resultData.forEach(item => {      
        reportDataExport.push({
          "PCN": item.pcn,
          "CUIL": item.cuit,
          "Documento": item.dni,
          "Fecha Captura": item.fecha,
          "Fecha Aprobado IC": item.fecha_modificacion,
          "Producto": item.producto,
          "NSE": item.nse, 
          "Percentil": item.percentil, 
          "Loc": item.loc, 
          "Limite": item.limite,
        });
      }); 
  
      this.excelExportService.exportAsExcelFile(reportDataExport, this.titleFile);
  
    }

    onConfirmEvent(confirm:any){
      console.log(confirm);
    }

  }