import { AbstractType, Component, ElementRef, OnInit} from '@angular/core';

import { LoadingComponent } from '../../component/shared/loading/loading.component';
import { InternalPreCheckService } from '../../services_internal/internalPrecheck/internalPrecheck.service';
import { VendorFilterModel, PreCheckFilterModel, AgenciesModel, Vendor } from './models/models';
import * as _ from 'lodash';
import { Environment } from 'src/app/config/environment';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { Utils } from 'src/app/commons/utils';
import { User } from 'src/app/auth/models/user.model';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { ProductosList } from 'src/app/models/lists.model';
import { BaseComponent } from '../../component/shared/base.component';
import { BusquedaFiltrosModel, InternalSourceCode } from 'src/app/models/aplicante.model';

@Component({
  selector: 'app-precheck',
  templateUrl: './internalPrecheck.component.html',
  styleUrls: ['./internalPrecheck.component.css']
})

export class InternalPreCheckComponent extends BaseComponent  implements OnInit {

   
  public title = "STATUS DE PROCESAMIENTO DE PRECHECK";
  public es: any;
  public showResult : boolean;
  public loading: boolean;
  public precheckDataList = [];
  public adicionalesList = [];
  public vendorsLisButtonText: string = "AGENCIAS";  
  public vendorsListEnabled: boolean;  
  public vendorsList :Array<VendorFilterModel> = new Array<VendorFilterModel>();  
  public productosList :ProductosList = new ProductosList();
  public agenciesList : AgenciesModel = new AgenciesModel();
  public precheckFilter : PreCheckFilterModel = new PreCheckFilterModel();
  public tablereports = null;
  public alertMessage: AlertModel = new AlertModel;
  public setDateFormat: Function = Utils.setDateFormat;
  public showAdicionalesEditar : boolean;
  public vendorRoles = [ Roles.AMX_ADMIN, Roles.AMX_MANAG];

  public vendorsListShow: boolean = true;
  public promotoresList: Vendor[] = new Array<Vendor>();
  public promotorUser: Vendor = new Vendor();
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;

  fechaProcesamiento : Date;
  telemarketingFormCheck: boolean;
  eapplyFormCheck: boolean;
  cenFormCheck: boolean;
  todasFuentesCheck: boolean;
  fuentesProcesadasLabel: string;
  vendorsLabel: string;
  

  constructor(private precheckService: InternalPreCheckService,
              private commonDataService: CommonDataService,
              private sessionStorageService: SessionStorageService,
              private excelExportService: ExcelExportService) { 
                super();
                this.sessionStorageService.rolValidate("internalReportes");
              }

  ngOnInit() {
    this.showResult=false;
    this.setCalendarLocale();
    this.loadVendors();
   // this.loadAgencies();   
    this.loadProductos();



    let user = this.sessionStorageService.getCurrentUser();

    if(this.sessionStorageService.hasRolesUser(this.vendorRoles))    
      this.vendorsListEnabled =  true;
    else {
      this.vendorsListEnabled =  false;
     // this.vendorsLisButtonText = Utils.isNullOrEmpty(user.agencia) ? this.vendorsLisButtonText : user.agencia;
    }

  }


  showVariblesSalida(value):boolean{
    let estados = ["DATO RECHAZADO POR CRITERIO NOSIS",
                   "DATO RECHAZADO POR CRITERIO VRZ"
                  ];  

    if(Utils.isNullOrEmpty(value))
        return false;

    return estados.includes(value);

  }

  getDetalleParse(aplicante: any) {

    let detalleReturn = [];

    for (var key in aplicante.nosisInt) {
      if (aplicante.nosisInt[key]) {

        if (key.includes("scoreNosis" || "nseIngresoprom")) { //son las variables que necesita mostrar de Nosis
          detalleReturn.push({ "key": key, "value": aplicante.nosisInt[key] });
        }
      }
    }
    if (aplicante.estado == "DATO RECHAZADO POR CRITERIO NOSIS") { //si es rechazado por criterio nosis, solo muestra las variables de nosis (solo paso por el primer modelo)
      return detalleReturn;
    } else if (aplicante.estado == "DATO RECHAZADO POR CRITERIO VRZ") { //si es rechazado por criterio VRZ,es que paso por ambos modelos (NOSIS/VRZ)
      for (var key in aplicante.equifaxInt) {
        if (aplicante.equifaxInt[key]) {
          if (key.includes("scoreVeraz" || "axpRiskScore" || "limiteTarjetaPreferida" || "incomeAvg")) {//son las variables que necesita mostrar de VRZ
            detalleReturn.push({ "key": key, "value": aplicante.equifaxInt[key] });
          }
        }
      }

    }

    return detalleReturn;
  }

  uncheckTodasFuentes(checkvalue: boolean) {

    if(this.todasFuentesCheck && !checkvalue){
      this.todasFuentesCheck = false;

    }
  }

  seleccionarTodasFuentes(){
   
    this.telemarketingFormCheck = this.todasFuentesCheck;
    this.eapplyFormCheck = this.todasFuentesCheck;   
   this.cenFormCheck = this.todasFuentesCheck;

    if(this.vendorsListEnabled) {
      this.agenciesList.data.forEach(i=>{
          i.selected = this.todasFuentesCheck;
      });
    }
  }

  setLabelsFilters(){

    let fuentesList = [];

    if(this.telemarketingFormCheck )
      fuentesList.push("Telemarketing");

    if(this.eapplyFormCheck )
      fuentesList.push("Eapply");

    if(this.cenFormCheck)
      fuentesList.push("CEN")

    this.fuentesProcesadasLabel =  fuentesList.join(" + ");
  
    let vendorsAll = (this.agenciesList.data.length == this.agenciesList.data.filter(v=>v.selected).length);

    if(vendorsAll)
      this.vendorsLabel = "TODOS";
    else if(this.agenciesList.data.filter(v=>v.selected).length == 0)
      this.vendorsLabel = "NINGUNO" 
    else
      this.vendorsLabel  = this.agenciesList.data.filter((i) => i.selected).map((i)=> i.name).join(" + ");  
   


  }

  loadReport(){

    this.rolesAutorization();

    if(!Utils.dateIsValid(this.precheckFilter.fecha_format_inicio)) {     
      this.alertMessage = new AlertModel("Fecha de Procesamiento Desde es requerida.", this.title, true, ALERT_TYPE.INFO);
      return;
    }
    
    if(Utils.dateIsValid(this.precheckFilter.fecha_format_inicio) &&
       Utils.dateIsValid(this.precheckFilter.fecha_format_fin)){

        let cap_days =  Utils.dateDiffBetween(this.precheckFilter.fecha_format_inicio, this.precheckFilter.fecha_format_fin, "days");

        if(cap_days < 0){
          this.alertMessage = new AlertModel("Fecha de Procesamiento: Desde debe ser mayor que Hasta.",this.title,true);
          return;
        }  

    }
   
    if(!this.telemarketingFormCheck && !this.eapplyFormCheck && !this.cenFormCheck
      && !this.agenciesList.data.some(v=> v.selected)){
      this.alertMessage = new AlertModel("Fuentes de procesamiento es requerida.", this.title, true, ALERT_TYPE.INFO);
      return;
    }

    this.setLabelsFilters();
    this.loading = true;
    this.showResult = false;
    
    this.precheckFilter.canales = [];  
    this.precheckFilter.promotores = []; 

    if(this.telemarketingFormCheck)
        this.precheckFilter.canales.push(("Telemarketing"));

    if(this.eapplyFormCheck)
        this.precheckFilter.canales.push(("Eapply"));

    if(this.cenFormCheck)
        this.precheckFilter.canales.push(("CEN"))


    let vendorsSelected = this.agenciesList.data.filter(v=> v.selected);

    if(vendorsSelected.length != 0) {
      this.precheckFilter.promotores = [];

      vendorsSelected.forEach(i=>{
        this.precheckFilter.promotores.push(i.id);
      });
    }
    
    this.precheckFilter.fecha_procesamiento_inicio = Utils.formatDateToCustomFormat(this.precheckFilter.fecha_format_inicio)
    this.precheckFilter.fecha_procesamiento_fin = Utils.formatDateToCustomFormat(this.precheckFilter.fecha_format_fin)

    this.loadReportRequest();

  }

  loadReportRequest(){
   
    let request = Object.assign({}, this.precheckFilter); 
    if(!Utils.dateIsValid(request.fecha_format_fin))
      request.fecha_procesamiento_fin = this.precheckFilter.fecha_procesamiento_inicio;  

    this.precheckService.getPrecheckData(request).subscribe(
      data => {
        if (data['code'] == "0") {
          this.precheckDataList = data['data'];

          this.precheckDataList.forEach(i=>{
            let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( i.informedProduct ) );
            i.productoObj = prod;
            if(i.internalSourceCode == null){
              i.internalSourceCode = new InternalSourceCode();
            }
             i.estado = (i.estado == null ? "" : i.estado.toLocaleUpperCase().replace("APROBAR", "APROBADO"));
             i.fuente = ( i.fuente == "Telemarketing" ) ? i.nombreArchivo : i.fuente;
          });

          this.setupTable();
   
        }
        else {

          let message  = data['message'] ? data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
          this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
          this.loading = false;
                    
        }
      },
      error => {
        
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        this.loading = false;

        this.setupTable();

      }
    );

  }

  tieneFechaProcesamientoFin():boolean{
   return Utils.dateIsValid(this.precheckFilter.fecha_format_fin);
  }

  loadVendors(){

    let user = this.sessionStorageService.getCurrentUser();

    this.vendorsListShow = true;

    this.precheckService.getAllPromotores().subscribe(
      response => {

        if( response.status == 200 && response.body != null){

          this.promotoresList = Object.assign([], response.body);
          this.agenciesList.data = this.promotoresList
          this.vendorsListEnabled =  true;
          
        }else if( response.status == 204 ){
          console.log( "no se encontraron promotores" );
        }else{
          console.log( "Error 1 obteniendo los promotores" );
        }

      }, err => {
        console.log( "Error 2 obteniendo los promotores" );
        this.loading = false;
        throw err;
      }
    );

  }

  loadAgencies(){
    
    this.precheckService.getAgenciesList().subscribe(
      response => {
        if( response.status == 200 && response.body != null && response.body['code'] == "0"){
          var data = response.body;
          this.agenciesList.data = data['data']
          this.vendorsListEnabled =  true;
    
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
    
        this.loading = false;
        },
      );
  }

  loadProductos(){
    console.log("busco productos");
    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
      }
    );

    
    

  }
 
  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 

    //Parseo de id prod a prod
    this.precheckDataList.forEach(i=>{  
      this.productosList.data.forEach(p =>{
        if(i.informedProduct == p.data.id){
          i.informedProduct = p.data.prod
        }
      })
     });
    
    $(document).ready( () => {
       this.tablereports = $("#precheck-result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers', 
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          {
            targets: 6,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
        ], 
        language: Environment.DataTableLanguageSetting
       });


    });

  }

  public rolConsulta:boolean=true;
  public rolesAutorization(){
    let rolesSession =this.sessionStorageService.getCurrentUser().roles

    if(rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  }

  alertaPermisosExportar(){
    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}
  }

  exportar(){

    this.alertaPermisosExportar();

    let reportExport = new Array<any>();

    this.precheckDataList.forEach(item => {

      if(item.variables_salida == null)
         item.variables_salida = []

      let objRow: any = new Object;
      objRow.fecha = item.fecha_procesamiento;
      objRow.pcn = item.pcn;
      objRow.cuil = item.cuil;
      objRow['digitos tarjeta'] = item.keyDigits;
      objRow.dni = item.dni;
      objRow.genero = item.intSocioData.gender;
      objRow.promotor = item.informedPromotor;
      objRow.promotor_original = item.informedPromotor;
      objRow.producto = item.productoObj.data.prod +" - "+ item.productoObj.value;
      objRow['tipo venta'] = item.tipoVenta;
      objRow.estado = item.estado;
      objRow.motivo = item.estadoRegistro;
      objRow.fuente = item.fuente;
      objRow.archivo = item.nombreArchivo;

      if( item.equifaxInt != null ){
       // objRow.limiteFinal = item.equifaxInt.limiteFinal;
        objRow[ "Score NOSIS" ] = item.nosisInt.scoreNosis;
        objRow[ "Score Veraz" ] = item.equifaxInt.scoreVeraz;
        objRow[ "Income Nosis" ] = item.nosisInt.nseIngresoprom;
        //objRow[ "Tenure" ] = item.equifaxInt.antiguedadBureau;
       // objRow[ "bcra_peor_sit_u12m" ] = item.equifaxInt.bcraPeorSitU12m;
       // objRow[ "peor_sit_bureau_u12m" ] = item.equifaxInt.peorSitBureau12;
        objRow[ "Lim_TC_Preferida" ] = item.equifaxInt.limiteTarjetaPreferida;
        objRow[ "income_avg" ] = item.equifaxInt.incomeAvg;
      }

      if (item.intRiesgoResponse != null){
        objRow[ "risk_var_c" ] = item.intRiesgoResponse.riskVarC;
        objRow[ "Limite Actual" ] = item.intRiesgoResponse.riskVarL;
        objRow.upgrade = item.intRiesgoResponse.upgrade;
        objRow.reverse = item.intRiesgoResponse.reverse;
        objRow.companion = item.intRiesgoResponse.companion;
      }
      
      for( var i = 0; i < item.intPersonaSuples.length; i++ ){
        let obSup = item.intPersonaSuples[ i ];
        objRow["dni_sup_"+( i+1 )] = obSup.docNumSuple;
        objRow["genero_sup_"+( i+1 )] = obSup.genero;
        objRow["vinculo_sup_"+( i+1 )] = obSup.vinculo;
        objRow["nombre_sup_"+( i+1 )] = ( !Utils.isNullOrEmpty( obSup.nombreCompleto ) ) ? obSup.nombreCompleto : obSup.nombre1 + " "+obSup.apellido ;
        objRow["estado_sup_"+( i+1 )] = ( obSup.denegado ) ? "DENEGADO" : "APROBADO";
        objRow["motivo_sup_"+( i+1 )] = obSup.estado_registro;
      }
      
      reportExport.push( objRow );

    });   

    this.excelExportService.exportAsExcelFile(reportExport, "STATUS DE PRECHECK_CUSTOMER");
  }

  


  setStringToDateFormat(value:Date){

    let day = value.getDate();
    let month = (value.getMonth() + 1)
    let year = value.getFullYear()

    let daytr = day < 9 ? '0' + day : day;
    let monthstr = month < 9 ? '0' + month : month;


     return daytr + "/" + monthstr + "/" + year;

  }

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  openPopupSuples(pcn: string) {
    const selectedData = this.precheckDataList.find(data => data.pcn === pcn);
    
    if (selectedData && selectedData.intPersonaSuples.length) {
      this.adicionalesList = selectedData.intPersonaSuples.map(item => ({
        ...item,
        nombreCompleto: item.nombreCompleto || `${item.nombre1} ${item.apellido}`,
        denegado: item.denegado ? "DENEGADO" : "APROBADO"
      }));
    } else {
      this.adicionalesList = [];
    }
  }

  adicionalesCancelar(){
    this.showAdicionalesEditar = false;
    this.adicionalesList = [];
  }

}
