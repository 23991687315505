import { AbstractType, Component, OnInit } from '@angular/core';

import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';



import { DeliveryService } from '../../services/delivery/delivery.service';
import { AplicantModel, DatosPersonalesModel, 
         DatosContactoModel, DatosLaboralesModel,
         DireccionModel, TelefonoModel, ObservacionesModel,
         EntregaDocumentacionModel, ObservacionesTackingModel, 
         ValidacionModel, AplicanteAdicionalModel, BusquedaFiltrosModel,         
        } from 'src/app/models/aplicante.model';

import {  TiposDocumentosList, VinculosList,
          NacionalidadesList, EstadosCivilList, ProvinciasList,
          TiposViviendasList, EducacionNivleslList, ProfesionesList,
          TrabajosTipolList, ProductosList, GenerosList, 
          AccionAplicanteList, MotivosAplicanteList, HorasList} 
          from 'src/app/models/lists.model';

import { CommonDataService } from 'src/app/services/commons/commondata.service';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { CRUD, Environment } from 'src/app/config/environment';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Utils } from 'src/app/commons/utils';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../shared/base.component';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})

export class DeliveryComponent  extends BaseComponent  implements OnInit {

  
  public title: string = "DELIVERY DE PRODUCTOS";
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showAplicanteDetalles : boolean;
  public showAdicionalesEditar : boolean;
  
  
  
  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  public loading: boolean;
  public loadingMotivo: boolean; 
  public fechaPactacionDisabled: boolean = true;
  public fechaProxContactoDisabled: boolean = true;

  

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public resultsData = [];
  public aplicanteCurrent: AplicantModel = new AplicantModel;
  public adicionalCurrent: AplicanteAdicionalModel = new AplicanteAdicionalModel;
 

  dateinfo : Date;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;

  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();
  
  public generosList: GenerosList = new GenerosList();
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public horasList: HorasList = new HorasList();
  
  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public vendorsList: Array<any> = new Array<any>();
  public productsApplicationSource: any = {data:[]};

  protected loadingListsBehavior: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadingLists: Array<any> = [
    {"load":"VENDORS", "finish": false},
    {"load":"ACCIONES", "finish": true},
    {"load":"PRODUCTOS", "finish": true}

    
  ];
    
 
  constructor(private commonDataService:CommonDataService,
              private deliveryService: DeliveryService, 
              private sessionStorageService: SessionStorageService) { 
                super();
                this.sessionStorageService.rolValidate("delivery");

                this.loadingListsBehavior.subscribe(data  => { 
                
                    if(this.loadingLists.filter(l=> !l.finish).length == 0){
                      this.loading = false;
                    }
                
                });  

  }

  ngOnInit() {

    this.loading = true;
    this.busquedafiltros.producto = "";
    this.busquedafiltros.promotor = "";
    
    this.setCalendarLocale();
    this.validationAccionLoad();
    this.loadVendors();
    this.loadProductos();  
    this.rolesAutorization();
        
  }

  setLoadingListsFinish(loadname){

    this.loadingLists.forEach(i=>{
      if(i.load == loadname){
        i.finish = true;
        this.loadingListsBehavior.next(null);
      }

    })
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }

  loadReportResults(){

    if(Utils.dateIsValid(this.busquedafiltros.fechaDesde) &&
     Utils.dateIsValid(this.busquedafiltros.fechaHasta)){
      let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fechaDesde, this.busquedafiltros.fechaHasta, "days");

      if(cap_days < 0){
        this.alertMessage = new AlertModel("Fecha: Desde debe ser mayor que Hasta.",this.title,true);
        return;
      }  
    }
    this.loading = true;
    this.loadReportRequest();


  }

  loadReportRequest(){   

    let request = { 
      "matriz": this.sessionStorageService.getCurrentUser()?.matriz,
      "dni": Utils.parseEmptyNull(this.busquedafiltros.dni),
      
      "nombreapellido": Utils.parseEmptyNull(this.busquedafiltros.nombre),
      "pcn": Utils.parseEmptyNull(this.busquedafiltros.pcn),
      "producto": Utils.parseEmptyNull(this.busquedafiltros.producto),
      "refnaps": Utils.parseEmptyNull(this.busquedafiltros.refnaps),
      "aprobado_lanas": Utils.parseEmptyNull(this.busquedafiltros.aprobado_lanas) ,
      "promotor": Utils.parseEmptyNull(this.busquedafiltros.promotor),
      "fechaDesde": Utils.parseEmptyNull(this.busquedafiltros.fechaDesde),
      "fechaHasta": Utils.parseEmptyNull(this.busquedafiltros.fechaHasta) 
  };      

    this.deliveryService.getApplicantsReportByArea(request).subscribe(
      data => {
        if (data['data']) {
          this.resultsData = data['data'];

          this.resultsData .forEach(a=>{
            if(Utils.isNullOrEmpty(a.aprobado_lanas)){
              a.aprobado_lanas = "-";
              a.estado_lanas = "-";
            }else{
              a.estado_lanas = "APROBADO";
            }        
            
            a.promotor = this.vendorsList.find(p=>p.id == (a.promotor??""))?.name ?? a.promotor;
            a.producto = this.productosList.data.find(p=>p.key == (a.producto??""))?.value ?? "";
            
          });
                              
          this.setupTable();             

        }
        else {
          console.error(data);
        }
      },
      error => {
        console.error(error);
      }
    );

    return true;
  }

  showAplicante(pcn:string){
      this.loading = true;
      let request = {
          "pcn" : pcn
      };

      this.deliveryService.getAplicanteDetalle(request).subscribe(
        data => {

          if (data && data['code'] == "100"){
            this.alertMessage = new AlertModel(data['message'] , this.title, true);
            this.loading = false;
            return;
          }

          if (data && data.data) {

            if(data.data.length == 0){
              this.loading = false;
              this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, "ERROR");   
              return;
            }

            this.aplicanteCurrent = Object.assign(this.aplicanteCurrent, data.data[0]);
            this.aplicanteCurrent.observaciones.observacion = "";
            if( this.aplicanteCurrent.observaciones_tracking.length ){
              this.aplicanteCurrent.observaciones_tracking.sort(function(a, b) {
                var textA = a.fecha;
                var textB = b.fecha;
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
              });
            }
            let accion = this.accionAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.accion);
            
            if(accion){
              this.aplicanteAccionChange(this.aplicanteCurrent.validacion.accion);
            } else{
              this.aplicanteCurrent.validacion.accion = "";
              this.aplicanteCurrent.validacion.motivo = "";
            }    

            this.aplicanteCurrent.adicionales = this.aplicanteCurrent.adicionales.filter(a=>{
              return !Utils.isNullOrEmpty(a.numero_documento);
            });


            if(Utils.isNullOrEmpty( this.aplicanteCurrent.detalles.hora_pactacion))
               this.aplicanteCurrent.detalles.hora_pactacion = "";

            if(Utils.isNullOrEmpty( this.aplicanteCurrent.detalles.hora_prox_contacto))
               this.aplicanteCurrent.detalles.hora_prox_contacto = "";               

            this.showAplicanteDetalles = true;
            this.showResult = false
            this.loading = false;    
            this.loadProductsApplicationSource();
            this.setupTootip();       
            window.scroll(0, 0);
            this.refactorCp();
            this.commonDataService.pingApplicantHoldTimerStart(pcn);
          }
          else {
            console.error(data);
            this.loading = false; 
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,ALERT_TYPE.WARNING);
          }
        },
        error => {
          console.error(error);
          this.loading = false; 
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,ALERT_TYPE.WARNING);
        }
      );      
      
 
    return true;
  }

  confirmSave(){

    if(!this.rolConsulta){
      this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
      return;
    } 
    
    if(!this.validationsForm()){
      this.validationsShow = true;
      this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
      return;
    }

    this.alertConfirmEvent = this.onConfirmEvent;
    this.alertMessage = new AlertModel("Confirma que desea registrar la información?",this.title,true, "CONFIRM");	

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveAplicanteData();
    }

    return true;
  }

  saveAplicanteData(){

    
    let aplicante: any = new Object;

    aplicante.usuario = this.sessionStorageService.getCurrentUser()?.username;    
    aplicante.promotor = this.sessionStorageService.getCurrentUser()?.promotor;
    
    aplicante.datos_personales = this.aplicanteCurrent.datos_personales;
    aplicante.datos_laborales = this.aplicanteCurrent.datos_laborales;
    aplicante.datos_contacto = this.aplicanteCurrent.datos_contacto;
    aplicante.datos_tarjeta = this.aplicanteCurrent.datos_tarjeta;
    aplicante.solicitud_tarjeta = this.aplicanteCurrent.solicitud_tarjeta;
    aplicante.detalles = this.aplicanteCurrent.detalles;
    
    aplicante.observaciones = this.aplicanteCurrent.observaciones;
    aplicante.validacion = this.aplicanteCurrent.validacion;    
    aplicante.adicionales = this.aplicanteCurrent.adicionales;

    this.saveAplicante(aplicante);
    return true;
  }

   saveAplicante(aplicante) {

    this.loading = true;

    this.deliveryService.saveApplicantDataByArea(aplicante).subscribe(
      data => {
        if (data != null && data['code'] == "0") {

          this.loading = false;          
          this.showAplicanteDetalles = false;
          this.showResult = true;          
          
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadReportResults();
          window.scroll(0, 0);   
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
      },
      error => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
        
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.",this.title, true, ALERT_TYPE.WARNING);
      }
    );   
    
    return true;
  }


  messageTrackingShow(){
      this.messagetrackingshow = true;
  }


  adicionalesList(){

    let adicionales = this.aplicanteCurrent.adicionales.filter((a)=>{

      return a.crud != CRUD.DELETE;
    });

    return adicionales;
  }


  adicionalesEditar(dni:any) {

    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

      return a.numero_documento == dni;
    });

    this.adicionalCurrent  = Object.assign({}, adicional);
    this.adicionalCurrent.crud = CRUD.UPDATE;

    this.showAdicionalesEditar = true;
    
  }

  adicionalesNuevo() {
     this.adicionalCurrent  = new  AplicanteAdicionalModel();
     this.adicionalCurrent.crud = CRUD.CREATE;

     this.showAdicionalesEditar = true;
 }

  adicionalesCancelar(){

    this.showAdicionalesEditar = false;
    this.adicionalCurrent = null;
  }

  adicionalesGuardar(){


    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

      return a.numero_documento == this.adicionalCurrent.numero_documento;
    });

    if(adicional) {
        let index = this.aplicanteCurrent.adicionales.indexOf(adicional);

        this.aplicanteCurrent.adicionales[index] = Object.assign({},  this.adicionalCurrent );
        
        
    }else{
      this.aplicanteCurrent.adicionales.push(this.adicionalCurrent);
      
    }

    this.showAdicionalesEditar = false;

  }

  validationAccionLoad(){

    this.deliveryService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;
  }

  aplicanteAccionChange(item){

    this.loadingMotivo = true;
    let request = {"parent" : item}

    this.deliveryService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];
          this.motivosAplicanteList.data = this.motivosAplicanteList.data.filter(m=>m.key != "ENTREGADA");

          let motivo = this.motivosAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.motivo);
          if(motivo == null){
            this.aplicanteCurrent.validacion.motivo = "";
          }
        
          this.motivosAplicanteList.data.sort((a, b)=> a.key.localeCompare(b.key));  

          this.aplicanteMotivoChange(this.aplicanteCurrent.validacion.motivo);
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        this.loadingMotivo = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title,true);
      }
    );    

    return true;
  }


  aplicanteMotivoChange(motivo){
    
    if(this.aplicanteCurrent.validacion.accion == "APROBADO LANAS" &&
       motivo == "FECHA PACTADA"){
        this.fechaPactacionDisabled = false;
        this.fechaProxContactoDisabled = true;
    }
    
    if(this.aplicanteCurrent.validacion.accion == "APROBADO LANAS" &&
       (motivo == "PACTAR ENTREGA" || motivo == "REPACTAR ENTREGA")){
     this.fechaPactacionDisabled = true;
     this.fechaProxContactoDisabled = false;
 }
  
  }
    
  
  backToResults(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;

    
    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.solicitud_tarjeta.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
    }

    return true;
  }

  public validationsForm(){
    this.validations = new Array<any>();

    if(this.accionAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.accion) == null){
      this.aplicanteCurrent.validacion.accion = "";
      this.validations.push({"validacion.accion" : "este campos es requerido."})
    }  

    if(this.motivosAplicanteList.data.find(v=>v.key==this.aplicanteCurrent.validacion.motivo) == null){
      this.aplicanteCurrent.validacion.motivo = "";
      this.validations.push({"validacion.motivo" : "este campos es requerido."})
    } 


    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.celular))
        this.validations.push({"datos_contacto.celular" : "este campos es requerido."})
 
    if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal))
        this.validations.push({"datos_contacto.email_personal" : "este campos es requerido."})

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
      let rxEmail = new RegExp(Environment.RegExEmail);
      if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
        this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
      }
    }  
    
    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.datos_contacto.email_personal)){
      let rxEmail = new RegExp(Environment.RegExEmail);
      if(!rxEmail.test(this.aplicanteCurrent.datos_contacto.email_personal)){
        this.validations.push({"datos_contacto.email_personal" : "este campos debe contener un email válido."});
      }
    }

    if(!this.fechaProxContactoDisabled){

        if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_prox_contacto))
          this.validations.push({"detalles.fecha_prox_contacto" : "este campos es requerido."});



        if(!Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_prox_contacto)){
        
            let b = moment(this.aplicanteCurrent.detalles.fecha_prox_contacto, "DD/MM/YYYY");
            
            var duration = moment().diff(b, 'days') 

            if(duration> 0)       
              this.validations.push({"detalles.fecha_prox_contacto" : "Fecha Próx. Contacto debe ser igual o posterior al día de hoy."})
        
        

        }

        if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.hora_prox_contacto))
            this.validations.push({"detalles.hora_prox_contacto" : "campo hora es requerido."});
      
        
    }



    if(!this.fechaPactacionDisabled){  
      if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_pactacion))
          this.validations.push({"detalles.fecha_pactacion" : "este campos es requerido."});


       

      if(!Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.fecha_pactacion)){
      
        let b = moment(this.aplicanteCurrent.detalles.fecha_pactacion, "DD/MM/YYYY");
        
        var durationDay = moment().diff(b, 'days') 

        if(durationDay> 0)       
          this.validations.push({"detalles.fecha_pactacion" : "Fecha Pactacion debe ser igual o posterior al día de hoy."})
      }   

  }
    return this.validations.length == 0;
  }

  public validationsMessage(name):string{
    if(!this.validationsShow)
      return null;

    this.validationsForm();

    let validation = this.validations.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  loadVendors(){

    if(Utils.storageLoad("vendors-deli")){
      this.vendorsList = Utils.storageLoad("vendors-deli");
      this.setLoadingListsFinish("VENDORS");
      return;
    }

    this.deliveryService.getVendorsList().subscribe(
      data => {
        if (data['data']) {
          
          this.vendorsList = Object.assign(this.vendorsList, data['data']);       
          this.vendorsList.sort((a, b)=> a.name.localeCompare(b.name));
          
          Utils.storageSave("vendors-deli",  this.vendorsList);  

        }
        else {
          console.error(data);
        }
        this.setLoadingListsFinish("VENDORS");

      },
      error => {
        this.setLoadingListsFinish("VENDORS");
        console.error(error);
      }
    );

    
    

  }

  loadProductos(){

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("PRODUCTOS");
      },
      error => {
        this.setLoadingListsFinish("PRODUCTOS");
      }
    );

    
    

  }

  public loadProductsApplicationSource(){

    let request = {
      "promotor" : this.aplicanteCurrent.detalles.promotor
    }

    this.deliveryService.getProductsApplicationSource(request).subscribe(
      data => {
        this.productsApplicationSource.data = data["data"]
        
        if(this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent.solicitud_tarjeta.application_source_desc) == null)
          this.aplicanteCurrent.solicitud_tarjeta.application_source_desc = "";
          this.disableInputRolConsulta()
      },
      error => console.error(error));   

  }
  public rolConsulta : boolean=true;

  public disableInputRolConsulta(){
  if (!this.rolConsulta){
    const inputs  = document.getElementsByClassName('form-control');
    const guardarBtn  = document.getElementsByClassName('rolConsultaButton');
    for(let i=0;i<inputs.length;i++){
      inputs[i].setAttribute("disabled","true")
    }
    for(let i=0;i<guardarBtn.length;i++){
      guardarBtn[i].setAttribute("disabled","true")      
    }
  }
}

public rolesAutorization(){
  let rolesSession = this.sessionStorageService.getCurrentUser().roles
    console.log(rolesSession)
  if (rolesSession.includes("AMX_CONSULTA")){
    this.rolConsulta=false
  }

}


  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        //info: false,
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
        // language:{
        //   url:'//cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json',
        //   info: "Página _PAGE_ de _PAGES_"
        // }
       });


    });

  }  
  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.resultsData.forEach(item => {      
      reportDataExport.push({
        "NOMBRE Y APELLIDO":item.nombreapellido,
        "CUIL":item.cuit,
        "DNI":item.dni,
        "PCN":item.pcn,
        "FECHA":item.fecha,
        "ULTIMA MODIF":item.fecha_modificacion,
        "PRODUCTO":item.producto,
        "PROMOTOR":item.promotor,        
        "ESTADO":item.estado,
        "ESTADO LANAS":item.estado_lanas,
        "FECHA LANAS":item.aprobado_lanas,
      });
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  }  

  setCalendarLocale(){ 
    this.es = Environment.CalendarLanguageSetting
  }

  getApplicationSourceDesc(){

    let application_source_desc = this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent.solicitud_tarjeta.application_source_desc)

    if(application_source_desc)
       return application_source_desc.value;

    return "";
  }
  
  public  setupTootip(){
    $(function () {
        (<any>$('[data-toggle="tooltip"]')).tooltip()
    })

    return true;
  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.aplicanteCurrent.observaciones.observacion=this.sanitizedText(this.aplicanteCurrent.observaciones.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorCp(){ 
    this.aplicanteCurrent.datos_personales.codigo_postal=this.sanitizedTextCp(this.aplicanteCurrent.datos_personales.codigo_postal); 
     }
 sanitizedTextCp(text:string):string{
   return text.replace(/[^0-9]/g,'')
 }

 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 
}
}
