import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})

export class AyudaComponent implements OnInit {
  public title: string = "Información";
  public mail: string = "soporte.amex@learsoft.com";
  
  constructor(){}
    ngOnInit(): void {

    }
}
 

